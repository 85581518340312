import { TextField } from '@mui/material'
import React from 'react'
import appMeta from '../../Assets/AppMeta/appMeta';

export const validateEmail = (value) => {
    // Regular expression for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
};


const EmailTextField = ({ email, setEmail, emailError, setEmailError }) => {
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Validate email in real-time
        if (!validateEmail(emailValue)) {
            setEmailError("Invalid email address");
        } else {
            setEmailError("");
        }
    };

    return (
        <TextField
            size="small"
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            error={Boolean(emailError)}
            helperText={emailError}
        />
    )
}

export default EmailTextField;
