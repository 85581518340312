import axios from "axios";
import { url } from "./../apis";
import { toast } from "react-toastify";
import actionType from "./../actions/actionTypes";
import * as toastMessage from "./../../Assets/Constants/ToastMessages";

export const signUp = (user) => {
  // console.log("SignUp Action: " + JSON.stringify(user));
  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/signup`, user)
      .then((res) => {
        if (!res?.data?.error) {
          sessionStorage.setItem("checksum", res?.data?.checksum);

          user.onSignupOTPClick();

          toast.dark(res?.data?.message, {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });
        } else {
          // console.log("Response: " + JSON.stringify(res));
          toast.dark(res?.data?.message, {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.SIGN_UP,
          payload: res.data,
        });
      })
      .catch((error) => {
        // toast.dark(toastMessage.SIGNUP_ERROR_MESSAGE, {
        //   position: "bottom-right",
        //   autoClose: 1000,
        //   type: "error",
        // });
        toast.dark(error?.response?.data?.message, {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        });
      });
  };
};

// export const sendEmailOtp = () => {
//   return (dispatch) => {
//     axios.post(`${url}/SCM/sendEmailVerifyOTP`).then((res) => {
//       console.log(res)
//       if (!res?.data?.error) {
//         sessionStorage.setItem("signup_jwt", res?.data?.signup_jwt);
//       }else {
//         // console.log("Response: " + JSON.stringify(res));
//         toast.dark(res?.data?.msg, {
//           position: "bottom-right",
//           autoClose: 1000,
//           type: "error",
//         });
//       }
//       dispatch({
//         type: actionType.SEND_EMAIL_OTP,
//         payload: res.data,
//       })
//     });
//   };
// };

export const logIn = (email_phone, password, storage) => {
  return (dispatch) => {
    axios
      .post(`${url}/SCM/login`, { email_phone, password })
      .then((res) => {
        if (!res?.data?.error) {
          if (storage === true) {
            localStorage.setItem("token", res?.data?.token);
          } else {
            sessionStorage.setItem("token", res?.data?.token);
          }
          toast.dark(toastMessage.LOGIN_SUCESS_MESSAGE, {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });
          dispatch({
            type: actionType.SIGN_IN,
            payload: res?.data,
          });
        } else {
          toast.dark(res?.data?.message, {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        // toast.dark(toastMessage.LOGIN_ERROR_MESSAGE, {
        //   position: "bottom-right",
        //   autoClose: 1000,
        //   type: "error",
        // });
        // console.log("ERROr: " + JSON.stringify(error.response));
        toast.dark(error?.response?.data?.message, {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        });
      });
  };
};

export const googleOAuthLogin = (newToken) => {
  return (dispatch) => {
    sessionStorage.setItem('token', newToken);

    dispatch({ type: actionType.SIGN_IN_OAuth, payload: newToken })

    // toast.dark(toastMessage.LOGIN_SUCESS_MESSAGE, {
    //   position: "bottom-right",
    //   autoClose: 1000,
    //   type: "success",
    // });
  }
}

export const verifyEmail = (otp, onLoginClick) => {
  let checksum = sessionStorage.getItem("checksum");
  // console.log(`signup_jwt ${signup_jwt}`);
  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/verify/email`, { checksum, otp })
      .then((res) => {
        // console.log("Response: " + JSON.stringify(res));
        if (!res?.data?.error) {
          // sessionStorage.setItem("signup_jwt", res?.data?.signup_jwt);

          onLoginClick()

          toast.dark(res?.data?.message, {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });
        } else {
          // console.log(`Res catch ${JSON.stringify(res)}`);
          toast.dark(toastMessage.EMAIL_VERIFICATION_ERROR_MESSAGE, {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.EMAIL_VERIFICATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        // console.log(`catch res:${error}`);
        toast.dark(toastMessage.EMAIL_VERIFICATION_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        });
        let response = {
          error: true,
        };
        dispatch({
          type: actionType.EMAIL_VERIFICATION,
          payload: response,
        });
        // toast.dark(`Unhandled Error: ${error}`, {
        //   position: "bottom-right",
        //   autoClose: 1000,
        //   type: "error",
        // });
      });
    // console.log(
    //   "After verifyEmailAction: " + signup_jwt + "\tOTP: " + email_pin
    // );
  };
};

export const verifyPhone = (phone_pin, referral_code, onLoginClick) => {
  const signup_jwt = sessionStorage.getItem("signup_jwt");
  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/verify/consumer_phone`, {
        signup_jwt,
        phone_pin,
        referral_code,
      })
      .then((res) => {
        if (!res?.data?.error) {
          // sessionStorage.setItem("signup_jwt", res?.data?.signup_jwt);

          onLoginClick();
        } else {
          toast.dark(res?.data?.msg, {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.PHONE_VERIFICATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        // console.log(error);
        toast.dark(toastMessage.PHONE_VERIFICATION_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        });
        // toast.dark(`Unhandled Error: ${error}`, {
        //   position: "bottom-right",
        //   autoClose: 1000,
        //   type: "error",
        // });
      });
  };
};

export const resendOtp = () => {
  const checksum = sessionStorage.getItem("checksum");
  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/resend/code`, { checksum })
      .then((res) => {
        if (!res?.data?.error) {
          sessionStorage.setItem("checksum", res?.data?.checksum);
          toast.dark(toastMessage.RESEND_OTP_SUCCESS_MESSAGE, {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });
        } else {
          toast.dark(toastMessage.RESEND_OTP_ERROR_MESSAGE, {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.RESEND_OTP,
          payload: res.data,
        });
      })
      .catch((error) => {
        toast.dark(toastMessage.RESEND_OTP_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        });
      });
  };
};

export const forgotPasswordUserVerify = ({ email, phone, onSendOtpClick }) => {
  return (dispatch) => {
    axios
      .post(`${url}/SCM/forgot/password`, { email })
      .then((res) => {
        // console.log("forgotPasswordUserVerify Response:\n" + res);
        if (!res?.data?.error) {
          sessionStorage.setItem("checksum", res?.data?.checksum);

          toast.dark(
            res?.data?.message,
            {
              position: "bottom-right",
              autoClose: 1000,
              type: "success",
            }
          );

          onSendOtpClick();
        } else {
          toast.dark(res?.data?.message, {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        toast.dark(error?.response?.data?.message, {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        });
        // console.log("forgotPasswordUserVerify Error:\n" + error);
        // error = error + "";
        // if (error.includes("400")) {
        //   toast.dark("User Not Registered", {
        //     position: "bottom-right",
        //     autoClose: 1000,
        //     type: "error",
        //   });
        // } else {
        //   toast.dark("Un-Handled Error, please try after sometime", {
        //     position: "bottom-right",
        //     autoClose: 1000,
        //     type: "error",
        //   });
        // }
      });
  };
};

export const forgotPasswordNewPassword = (newPassword, otp, onLoginClick) => {
  const checksum = sessionStorage.getItem("checksum");

  return (dispatch) => {
    axios
      .post(`${url}/SCM/reset/password`, {
        checksum,
        newPassword,
        otp,
      })
      .then((res) => {
        if (!res?.data?.error) {
          sessionStorage.removeItem("checksum");

          toast.dark(res?.data?.message, {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });
          dispatch({
            type: actionType.FORGOT_PASSWORD_USER_VERIFY_OTP,
            payload: res.data,
          });
        } else {
          toast.dark(res?.data?.msg, {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });
        }

        onLoginClick();
      })
      .catch((error) => {
        toast.dark(toastMessage.FORGOT_PASSWORD_USER_VERIFY_OTP_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 1000,
          type: "error",
        });
      });
  };
};

export const signOut = () => {
  return (dispatch) => {
    // dispatch({
    //   type: "CLEAR_TODOS",
    // });

    dispatch({
      type: actionType.SIGN_OUT,
    });
  };
};



export const ReferalCodeVerify = (referal) => {

  return async (dispatch) => {
    await axios
      .get(`${url}/SSM/verify/referral?referral_id=${referal}`)
      .then((res) => {
        dispatch({
          type: actionType.REFERAL_CODE_VERIFY,
          payload: res.data,
        });
      })
      .catch((error) => {
        // console.log(error)
        dispatch({
          type: actionType.REFERAL_CODE_VERIFY,
          payload: { error: true },
        });
      });
  };
};
