import axios from "axios";
import actionTypes from "./actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { url } from '../apis';

const backendUrl = `${url}/SCM`;

export const GetBuildingDetails = () => {
    let api = useAxios();

    return async (dispatch) => {
        dispatch({ type: actionTypes.ADD_BUILDING_REQUEST });

        return await api
            .get(`${backendUrl}/building`)
            .then(res => {
                console.log(res);

                if (res.status === AppMetaData.httpStatus.SUCCESS) {
                    console.log(res?.data);

                    dispatch({ type: actionTypes.ADD_BUILDING_SUCCESS, payload: res?.data?.data[0] });
                }
                else {

                    dispatch({ type: actionTypes.ADD_BUILDING_FAILED });
                }
            })
            .catch(err => {
                console.log(err);

                dispatch({ type: actionTypes.ADD_BUILDING_FAILED });
            })
    }
}

export const GetFloorRoomDetails = ({ house_id }) => {
    let api = useAxios();

    return async (dispatch) => {
        dispatch({ type: actionTypes.ADD_FLOOR_ROOM_REQUEST });

        return await api
            .get(`${backendUrl}/building/room?house_id=${house_id}`)
            .then(res => {
                console.log(res);

                if (res.status === AppMetaData.httpStatus.SUCCESS) {
                    console.log(res?.data);

                    dispatch({ type: actionTypes.ADD_FLOOR_ROOM_SUCCESS, payload: res?.data?.data });
                }
                else {
                    dispatch({ type: actionTypes.ADD_FLOOR_ROOM_FAILED });
                }
            })
            .catch(err => {
                console.log(err);

                dispatch({ type: actionTypes.ADD_FLOOR_ROOM_FAILED });
            })
    }
}

export const AddBuildingDetails = (buildingName) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .post(`${backendUrl}/building`, { name: buildingName })
            .then(res => {
                console.log(res);

                if (res.status === AppMetaData.httpStatus.SUCCESS) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const AddFloorRoomDetails = ({ house_id, floor_number, room_name, image_url }) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .post(`${backendUrl}/building/room`, { house_id, floor_number, room_name, image_url })
            .then(res => {
                console.log(res);

                if (res.status === AppMetaData.httpStatus.SUCCESS) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

