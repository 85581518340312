import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  console.log("--------- ProtectedRoute.jsx ---------");

  const { isLoggedIn } = useSelector((state) => state.authReducer);
  // console.log("isLoggedIn", isLoggedIn);

  // if (!(localStorage.getItem("token") || sessionStorage.getItem("token"))) {
  if (!isLoggedIn) {
    return <Navigate to="/authenticate" replace={true} />;
  }

  return children;
};

export default ProtectedRoute;
