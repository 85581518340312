import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Link,
  TextField,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { verifyEmail, verifyPhone } from "../../Redux/actions/authAction";
import OTPTextField from "../TextFields/OTPTextField";

const LogoImg = styled("img")({
  width: 50,
});

const SignUpVerifyOTP = ({ onLoginClick, onSignupClick }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate OTP
    // Add your validation logic here for OTP
    if (!otp) {
      setOtpError("Please enter OTP");
      return;
    } else {
      setOtpError("");
    }

    // Dispatch action for verifying OTP during sign-up
    // dispatch(verifyPhone(otp, "", onLoginClick));
    dispatch(verifyEmail(otp, onLoginClick));

    // Reset fields after submission
    setOtp("");
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
      }}
    >
      <Avatar variant="rounded" sx={{ m: 1, mb: 2, bgcolor: "white", width: 270, height: 55 }}>
        <LogoImg sx={{width: "100%"}} src="/logo1.png" alt="Logo"  />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign Up Verification
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <OTPTextField
          otp={otp}
          setOtp={setOtp}
          otpError={otpError}
          setOtpError={setOtpError}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 1, backgroundColor: "#2196F3", color: "#ffffff" }}
        >
          Verify and Sign Up
        </Button>
        <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
        <hr style={{ flex: 1, margin: "0 8px" }} />
        <span style={{ margin: "0 8px" }}>or</span>
        <hr style={{ flex: 1, margin: "0 8px" }} />
      </div>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
          onClick={onLoginClick}
        >
          Back to Sign in
        </Button>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="space-between">
            <Link
              href="#"
              variant="body2"
              onClick={(e) => {
                e.preventDefault();
                onLoginClick();
              }}
            >
              Back to Sign in
            </Link>
            <Link
              href="#"
              variant="body2"
              onClick={(e) => {
                e.preventDefault();
                onSignupClick();
              }}
            >
              Back to Sign Up
            </Link>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
};

export default SignUpVerifyOTP;
