
const actionTypes = {
  RENDER_DASHBOARD: "RefreshDashboardButtonAndTitle",
  APP_META_DATA: "GET: getAppMetaData",
  SIGN_UP: "POST: signUp",
  SIGN_IN: "POST: logIn",
  SIGN_IN_OAuth: "POST: logIn",
  EMAIL_VERIFICATION: "emailVerification",
  RESEND_OTP: "resendOtp",
  SEND_EMAIL_OTP: "POST: sendEmailOtpForVerification",
  PINCODE_AVAILABILITY: "POST: searchForPincodeAvailability",
  PHONE_VERIFICATION: "phoneVerification",
  FORGOT_PASSWORD_USER_VERIFY_OTP: "forgotPasswordUserVerifyOtp",
  FORGOT_PASSWORD_NEW_PASSWORD: "forgotPasswordNewPassword",
  SIGN_OUT: "signOut",
  KEEP_ALIVE: "keepAlive",
  GET_USER_PROFILE: "getUserProfile",
  GET_FILES: "getFiles",
  SEARCH_PDW_AVAILABILITY: "POST: searchPackagedDrinkingWaterAvailability",
  FORGOT_PASSWORD_EMAIL_PHONE: "POST: verifyingOTP",
  GET_FILES_DOWNLOAD: "getFilesDownload",
  GET_RECENT_ACTIVITIES: "getRecentActivities",
  GET_TRANSACTION_DETAILS: "getTransactionDetails",
  GET_PDW_PAYMENT_PENDING: "GET: getPackagedDrinkingWaterPaymentPending",
  GET_PDW_ONGOING_TRIPS: "GET: getPackagedDrinkingWaterOngoingTrips",
  GET_PDW_COMPLETED_TRIPS: "GET: getPackagedDrinkingWaterCompletedTrips",
  GET_PDW_ALL_ORDERS: "GET: getPackagedDrinkingWaterAllOrders",
  GET_WST_PAYMENT_PENDING: "GET: getWaterSewageTankerPaymentPending",
  GET_WST_ONGOING_TRIPS: "GET: getWaterSewageTankerOngoingTrips",
  GET_WST_COMPLETED_TRIPS: "GET: getWaterSewageTankerCompletedTrips",
  GET_WST_ALL_ORDERS: "GET: getWaterSewageTankerAllOrders",
  LOADING_DATA: "FetchingOrPostDatatoServer",
  SETTINGS: "userSettings",
  UPDATE_USER_PROFILE: "PUT: updateUserProfile",
  UPDATE_PASSWORD: "POST: changeUserPassword",
  UPDATE_PHONE_NUMBER: "POST: changeCurrentPhoneNumber",
  UPDATE_PHONE_NUMBER_VERIFY: "PUT: verifyConsumerPhoneNumber",
  UPDATE_EMAIL: "POST: changeCurrentEmail",
  UPDATE_EMAIL_VERIFY: "PUT: verifyConsumerEmail",
  UPLOAD_FILE: "POST: uploadFiles",
  UPLOAD_PO_FILE: "POST: uploadPoFileWithOrderDetails",
  GET_USER_ADDRESS_LIST: "GET: getUserAdrressBookList",
  DELETE_USER_ADDRESS: "PUT: deletUserAddress",
  ADD_NEW_ADDRESS: "POST: addNewAddress",
  EDIT_EXISTING_ADDRESS: "PUT: editAddress",
  GET_VIRTUAL_ACCOUNTS_DETAILS: "GET: getUserVirtualBankAccountDetails",
  GET_USER_ACCOUNTS_LIST: "GET: getUserBankAccountsList",
  DELETE_USER_ACCOUNT: "PUT: deletUserBankAccount",
  ADD_NEW_ACCOUNT: "POST: addNewBankAccount",
  EDIT_EXISTING_ACCOUNT: "PUT: editExistingAccount",
  DELETE_USER_BANK_ACCOUNT: "PUT: deleteUserBankAccount",
  SEARCH_AVAILABILITY: "POST: searchAvailabilitybyPincode",
  WATER_TANKER_LIST: "GET: listTheWaterTankersForTheServiceArea",
  SEWAGE_TANKER_LIST: "GET: listTheSewageTankersForTheServiceArea",
  PDW_TANKER_LIST: "GET: listThePackagedDrinkingWaterTankersForTheServiceArea",
  BOOK_TANKER_ORDER: "POST: bookAWaterOrSewageTanker",
  BOOK_PDW_ORDER: "POST: bookPackagedDrinkingWater",
  PAYMENT_PENDING_TANKER: "GET: paymentPendingTanker",
  ONGOING_TANKER: "GET: ongoingTanker",
  COMPLETED_TANKER: "GET: completedTanker",
  ALLORDERS_TANKER: "GET: allOrdersTanker",
  ORDER_DETAILS: "GET: getOrderDetails",
  DELIVERY_DETAILS: "GET: getDeliveryDetails",
  PAYSHARP_HANDLE_STATUS: "POST: enableOrDisablePaysharp",
  PAY_BY_RAZORPAY: "POST: payThroughRazorPay",
  PAY_BY_RAZORPAY_NEW: "POST: payThrougnRazorPayNew",
  PAY_BY_PAYSHARP_UPI: "POST: PaythroughPaysharpUPI",
  PAY_BY_VPA_PAYSHARP_UPI: "POST: PaythroughVPAPaysharpUPI",
  PAY_BY_VPA_PAYSHARP_UPI_REQUEST: "POST: PaythroughVPAPaysharpUPIRequest",
  PAY_BY_UPI_ORDER_STATUS: "POST: PaythroughUPIOrderStatus",
  UPI_ORDER_STATUS: "upiOrderStatus",
  GET_WALLET_DETAILS: "GET: getWalletDetails",
  GET_WALLET_TRANSACTIONS: "GET: getWalletTransactionsDetails",
  PAY_THROUGH_WALLET: "POST: paythroughWalletForAnOrder",
  GET_DEPOSIT_DETAILS: "GET: getDepositDetails",
  GET_QR_DATA: "POST: scannedQrDetails",
  GET_USER_STOCK: "GET: getTheUserStockDetails",
  GET_DEPOSIT_DATA: "GET: getDepositData",
  GET_CONFIG_DATA: "GET: getConfigDetails",
  PUT_START_ORDER: "PUT: putStartOrder",
  GET_QR_REPORT_QUALITY: "GET: getQrQualityReport",
  INVOICE_DETAILS: "GET: getInvoiceDetails",
  DOWNLOAD_INVOICE: "GET: downloadInvoiceDetails",
  CANCEL_ORDER: "POST: cancelOrder",
  GET_SUBSCRIPTION_DETAILS: "GET: getSubscriptionOrderDetails",
  BOOK_PDW_SUBSCRIBE_ORDER: "POST: bookSubscribedPackageDrinkingWater",
  UPDATE_PDW_SUBSCRIBE_ORDER: "PUT: updateSubscriptionOrder",
  GET_PWD_ORDER_OTP: "getPwdOrderOtp",
  GET_BANK_DOWN_TIME: "getBankDownTime",
  GET_REPEAT_ORDER: "GET: getRepeatOrderDetails",
  // SEND_EMAIL_OTP: "POST: sendEmailOtp",
  EDIT_ORDER_USER: "PUT: editOrderUser",
  RETURN_CANS_REQUEST: "POST: returnCansRequest",
  GET_PWD_PARTICULAR_ORDER: "getParticularOrderId",
  // GET_RETURN_CAN_LIST: "GET: returnReturnCanList",
  RETURN_CANS_OTP: "GET: returnCansOtp",
  REPLACEMENT_CANS_OTP: "GET: replacementCansOtp",
  DAMAGE_CANS_DATA: "GET: damageCansData",
  DAMAGE_CANS_ACCEPT: "PUT: damageCansAccept",
  GET_BANNERS: "getBanners",
  // PINCODE_AVAILABILITY: "pincodeAvailability",
  REQUEST_REPLACEMENT_CAN: "requestReplacementCan",
  BANNERS_IMG: "bannersImg",
  GET_FILES_BANNER: "getFilesBanner",
  GET_PROMO_CODES: "getPromoCodes",
  GET_REFERRAL_CODES: "getReferralCodes",
  APPLY_PROMO_CODE: "applyPromoCodes",
  APPLY_WATER_DROPS: "applyWaterDrops",
  GET_WATER_DROPS: "getWaterDrops",
  REQUEST_INFLUENCER: "requestInfluencer",
  GET_DELIVERY_SLOTS_LIST: "getDeliverySlotList",
  REQ_DELIVERY_TIME: "requestDeliveryTime",
  INFLUENCER_AUTO_PAY: "InfluencerAutoPayAction",
  WATER_DROP_TRANSACTION: "waterDropTransactions",
  CONSUMER_BK_DETAILS: "consumerBkDet",
  GET_BANK_DETAILS_IFSC: "consumerIfscBkDet",
  WATER_DROP_SETTLEMENT: "waterDropSettlements",


  //For resetting component state
  RESET_COMPONENT_STATE: "reset_componenet_state",

  RESET_PROMO_CODE: "rest_promocode_details",
  REFERAL_CODE_VERIFY: "refer_code_verification",
  GET_QUALITY_REPORT: "files_banner",
  RESET_TOGGLE_STATE: "rest_toggle_state",
  RESET_DARK_THEME: "reset_dark_theme",





// Home1.js (Devices)
  GET_OTBR_LIST: 'GET_OTBR_LIST',
  GET_COMMISSIONER_LIST :'GET_COMMISSIONER_LIST',
  GET_DEVICES_LIST_REQUEST: 'GET_DEVICES_LIST_REQUEST',
  GET_DEVICES_LIST_SUCCESS: 'GET_DEVICES_LIST_SUCCESS',
  GET_DEVICES_LIST_FAILED: 'GET_DEVICES_LIST_FAILED',
  
  FAILED: 'FAILED',
  GET_Commissioner_Status: 'GET_Commissioner_Status',
  Start_Commissioner: 'Start_Commissioner',
  Stop_Commissioner: 'Stop_Commissioner',
  Send_Key: 'Send_Key',
  Light_Control: 'Light_Control',
  Fan_Control: 'Fan_Control',
  Fan_Speed_Control: 'Fan_Speed_Control',
  
  ADD_BUILDING_REQUEST: 'ADD_BUILDING_REQUEST',
  ADD_BUILDING_SUCCESS: "ADD_BUILDING_SUCCESS",
  ADD_BUILDING_FAILED: 'ADD_BUILDING_FAILED',

  ADD_FLOOR_ROOM_REQUEST: 'ADD_FLOOR_ROOM_REQUEST',
  ADD_FLOOR_ROOM_SUCCESS: "ADD_FLOOR_ROOM_SUCCESS",
  ADD_FLOOR_ROOM_FAILED: 'ADD_FLOOR_ROOM_FAILED',
};

export default actionTypes;



// homeActionTypes.js
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const ADD_FLOOR_SUCCESS = 'ADD_FLOOR_SUCCESS';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const UPDATE_DEVICE_STATUS_SUCCESS = 'UPDATE_DEVICE_STATUS_SUCCESS';





