import { LightbulbOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, Paper, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModeFanOffOutlinedIcon from '@mui/icons-material/ModeFanOffOutlined';
import { FanControlAction, FanSpeedControlAction, GetCommissionerDetails, GetCommissionerStatus, GetDevicesList, GetOtbrList, LightControlAction, SendKeyAction, StartCommissionerAction, StopCommissionerAction } from '../../Redux/actions/devicesAction';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const Home1 = () => {
  console.log("--------- Home1.jsx ---------");

  const [showDevices, setShowDevices] = useState(false);
  const dispatch = useDispatch();
  const { otbrDetails, commissionerDetails, devicesList } = useSelector(state => {
    return {
      otbrDetails: state?.devicesReducer?.otbrs[0],
      commissionerDetails: state?.devicesReducer?.commissioners[0],
      devicesList: state?.devicesReducer?.devices
    }
  }, shallowEqual)

  useEffect(() => {
    function fetchData() {
      dispatch(GetOtbrList());
      dispatch(GetCommissionerDetails());
      dispatch(GetDevicesList());
    }

    // Run fetchData initially
    fetchData();

    // Set up interval to run fetchData every 15 seconds
    const intervalId = setInterval(fetchData, 15000);

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const toggleDevices = () => {
    setShowDevices(!showDevices);
  };

  return (
    <div>
      {showDevices ? <DevicesListCard otbrName={otbrDetails?.endpoint} commissionerDetails={commissionerDetails} devicesList={devicesList} toggleDevices={toggleDevices} /> : <BorderRouterCard otbrDetails={otbrDetails} toggleDevices={toggleDevices} />}
    </div>
  )
}

const ZoomCard = styled(Card)`
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
  `

const BorderRouterCard = ({ otbrDetails, toggleDevices }) => {
  return (
    <Stack direction="row" spacing={2}>
      {
        otbrDetails &&
        <ZoomCard sx={{ width: 290 }} onClick={toggleDevices}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">{otbrDetails?.endpoint}</Typography>
              <Typography gutterBottom variant="body1" component="p"> Mac Address: {String(otbrDetails?.resourcedefs[7]?.value)}</Typography>
              <Typography gutterBottom variant="body1" component="p">Status: {String(otbrDetails?.resourcedefs[0]?.value) === "leader" ? "Active" : String(otbrDetails?.resourcedefs[0]?.value) === "detaching" ? "Activating" : "Disable"}</Typography>
              {/* <Typography gutterBottom variant="body2" component="b" color="gray">Connected Devices: {String(otbrDetails?.resourcedefs[9]?.value)}</Typography> */}
            </CardContent>
          </CardActionArea>
        </ZoomCard>
      }
      <ZoomCard sx={{ width: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CardActionArea>
          <CardContent sx={{ textAlign: 'center' }}>
            <IconButton>
              <AddCircleOutlineIcon sx={{ fontSize: 57 }} />
            </IconButton>
          </CardContent>
        </CardActionArea>
      </ZoomCard>
    </Stack>
  );
};

const DevicesListCard = ({ otbrName, commissionerDetails, devicesList, toggleDevices }) => {
  const [open, setOpen] = useState(false);
  // const [commissionerState, setComissionerState] = useState(commissionerDetails?.resourcedefs ? commissionerDetails?.resourcedefs[1]?.value == "active" ? commissionerDetails?.resourcedefs[1]?.value : "disabled" : "disabled");
  const [textFieldValue, setTextFieldValue] = useState('');
  const dispatch = useDispatch();
  const { commissionerState } = useSelector(state => {
    return {
      commissionerState: state?.devicesReducer?.commissionerState
    }
  })

  useEffect(() => {
    dispatch(GetCommissionerStatus(commissionerDetails));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartCommissioner = () => {
    dispatch(StartCommissionerAction(commissionerDetails))
      .then(res => {
        if (res) {
          dispatch(GetCommissionerStatus(commissionerDetails));

          toast.dark("Commissioner started", {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });

          console.log('Commissioner started');
        }
        else {
          toast.dark("Failed to start commissioner", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log('Failed to start commissioner');
        }
      })
  }

  const handleStopCommissioner = () => {
    dispatch(StopCommissionerAction(commissionerDetails))
      .then(res => {
        if (res) {
          dispatch(GetCommissionerStatus(commissionerDetails));

          toast.dark("Commissioner stopped", {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });

          console.log('Commissioner stopped');
        }
        else {
          toast.dark("Failed to stop commissioner", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log('Failed to stop commissioner');
        }
      })
  }

  const handleSendKey = () => {
    dispatch(SendKeyAction(commissionerDetails, textFieldValue))
      .then(res => {
        if (res) {
          handleClose();

          toast.dark("Key added successfully", {
            position: "bottom-right",
            autoClose: 1000,
            type: "success",
          });

          console.log('Key added successfully');
        }
        else {
          toast.dark("Invalid key", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log('Failed to add key');
        }

        setTextFieldValue("");
      })
  }

  return (
    <>
      <Stack direction="column" spacing={1} marginBottom={2}>
        <Box style={{ display: 'flex', alignItems: 'center', color: 'blue' }}>
          <ArrowBackIcon onClick={toggleDevices} style={{ cursor: 'pointer', marginRight: '5px' }} />
          <Typography onClick={toggleDevices} style={{ cursor: 'pointer' }} variant="h6">
            Back
          </Typography>
        </Box>
        {/* <Typography variant="h6" onClick={toggleDevices} style={{ cursor: 'pointer', color: 'blue' }}>
          <ArrowBackIcon /> Back
        </Typography> */}

        <Card>
          <CardContent>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="column" alignItems="flex-start">
                <Typography variant="h5" style={{ textAlign: 'left' }}>{otbrName}</Typography>
                {/* <Typography variant="body1" component="p" style={{ textAlign: 'left', color: 'rgba(0, 0, 0, 0.6)' }}>Manage all your devices at one place.</Typography> */}
              </Stack>
              <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={() => { handleClickOpen(); GetCommissionerStatus() }} >
                Add Device
              </Button>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>Add Device</DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} alignItems="center" justify="center">
                    <Grid item xs={12}>
                      <DialogContentText style={{ textAlign: 'center' }}>
                        Commissioner State: {commissionerState}
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Button variant="contained" onClick={handleStartCommissioner} color="primary" fullWidth>
                            Start
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button variant="contained" onClick={handleStopCommissioner} color="primary" fullWidth>
                            Stop
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        disabled={commissionerState == "active" ? false : true}
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Enter Key"
                        type="text"
                        fullWidth
                        value={textFieldValue}
                        onChange={(e) => setTextFieldValue(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button disabled={commissionerState == "active" ? false : true} variant="contained" onClick={handleSendKey} color="primary" fullWidth>
                        Add Device
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>

            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <Grid container spacing={2} marginBottom={2}>
        {devicesList?.length > 0 &&
          devicesList.map((el) => {
            return (
              <Grid item key={`${el.endpoint}-${el.id}`}>
                {el.id === 32788 && <LightDeviceCard device={el} icon="" />}
                {el.id === 32790 && <FanDeviceCard device={el} icon="" />}
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

const LightDeviceCard = ({ device }) => {
  const dispatch = useDispatch();
  const [isOn, setIsOn] = useState(device?.resourcedefs[0]?.value ? device?.resourcedefs[0]?.value : false);

  useEffect(() => {

  }, [device?.resourcedefs[0]?.value]);

  const handleSwitchChange = () => {
    setIsOn(prevIsOn => {
      const newValue = !prevIsOn;
      lightControl(newValue);
      return newValue;
    });
  };

  const lightControl = (value) => {
    dispatch(LightControlAction(device, value))
      .then(res => {
        if (res) {
          dispatch(GetDevicesList());

          // toast.dark("Data sent successfully", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   type: "success",
          // });

          console.log('Data sent successfully');
        }
        else {
          setIsOn(!value);

          toast.dark("Failed to send data", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log("Failed to send data");
        }
      });
  }

  return (
    <Card variant="outlined" style={{ width: 270, borderRadius: 9 }}>
      <CardContent>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6" gutterBottom>
            {device?.endpoint}
          </Typography>
          <Box
            boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
            borderRadius="50%"
            p={1}
            bgcolor="transparent"
            display="inline-block"
            // marginTop={1}
            marginBottom={2}
          >
            <LightbulbIcon style={{ fontSize: 45, margin: 7, color: isOn ? `rgb(255, 255, 0)` : 'inherit' }} />
          </Box>

          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h6" align="left">Power</Typography>
            <Switch checked={isOn} onChange={handleSwitchChange} />
          </Box>

          {/* Additional resource values */}
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left">Light Status:</Typography>
            <Typography variant="body1" align="right">{isOn ? "ON" : "OFF"}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left">Default Voltage:</Typography>
            <Typography variant="body1" align="right">{device?.resourcedefs[2]?.value}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left">ADC Raw Data:</Typography>
            <Typography variant="body1" align="right">{device?.resourcedefs[3]?.value}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left">Default Voltage:</Typography>
            <Typography variant="body1" align="right">{device?.resourcedefs[4]?.value}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left">Voltage:</Typography>
            <Typography variant="body1" align="right">{Number(device?.resourcedefs[5]?.value)?.toFixed(2)}</Typography>
          </Box>
          {/* End of additional resource values */}
        </Stack>

      </CardContent>
    </Card>
  );
}

const FanDeviceCard = ({ device }) => {
  const dispatch = useDispatch();
  const [isOn, setIsOn] = useState(device?.resourcedefs[1]?.value ? device?.resourcedefs[1]?.value : false);
  const [speed, setSpeed] = useState(device?.resourcedefs[2]?.value ? device?.resourcedefs[2]?.value : 0);

  const handleSwitchChange = () => {
    setIsOn(prevIsOn => {
      const newValue = !prevIsOn;
      fanControl(newValue);
      return newValue;
    });
  };

  const handleSliderChange = (e) => {
    const updatedValue = e.target.value;
    console.log(updatedValue);
    // fanSpeedControl(updatedValue);
    // setSpeed(updatedValue);
    debounceFn(updatedValue);
  };

  let debounceTimer;
  const debounceFn = (updatedValue) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      fanSpeedControl(updatedValue);
      setSpeed(updatedValue);
    }, 500); // 500 ms
  };

  const fanControl = (value) => {
    dispatch(FanControlAction(device, value))
      .then(res => {
        if (res) {
          dispatch(GetDevicesList());

          // toast.dark("Data sent successfully", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   type: "success",
          // });

          console.log('Data sent successfully');
        }
        else {
          setIsOn(!value);

          toast.dark("Failed to send data", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log("Failed to send data");
        }
      });
  }

  const fanSpeedControl = (value) => {
    dispatch(FanSpeedControlAction(device, value))
      .then(res => {
        if (res) {
          dispatch(GetDevicesList());

          // toast.dark("Data sent successfully", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   type: "success",
          // });

          console.log('Data sent successfully');
        }
        else {
          toast.dark("Failed to send data", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log("Failed to send data");
        }
      });
  }

  return (
    <Card variant="outlined" style={{ width: 270, borderRadius: 9 }}>
      <CardContent>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6" gutterBottom>
            {device?.endpoint}
          </Typography>
          <Box
            boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
            borderRadius="50%"
            p={1}
            bgcolor="transparent"
            display="inline-block"
            // marginTop={1}
            marginBottom={2}
          >
            <ModeFanOffOutlinedIcon style={{ fontSize: 45, margin: 7, color: isOn ? `rgb(255, 165, 0)` : 'inherit' }} />
          </Box>

          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h6" align="left">Power</Typography>
            <Switch checked={isOn} onChange={handleSwitchChange} />
          </Box>

          {/* Additional resource values */}
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left">Fan Status:</Typography>
            <Typography variant="body1" align="right">{isOn ? "ON" : "OFF"}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left">Speed Control:</Typography>
            <Slider
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
              defaultValue={device?.resourcedefs[2]?.value}
              step={1}
              marks
              min={0}
              max={5}
              style={{ width: '100px' }}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default Home1;
