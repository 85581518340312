import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Authentication from "./Pages/Authentication/Authentication";
import ProtectedRoute from "./Pages/ProtectedRoute";
import HandleResetPasswordLink from "./Components/Authentication/HandleResetPasswordLink";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./Pages/Common/Dashboard";
import ScreenScroll from "./ScreenScroll";
import MobileView from "./Pages/MobileView/MobileView";
import AuthBackground from "./Pages/Authentication/AuthBackground";
import { CssBaseline } from "@mui/material";
import React from "react";
import Dashboard1 from "./Pages/Common/Dashboard1";

function App() {

  return (
    <div>
      <CssBaseline />
        <BrowserRouter>
          <ScreenScroll />
          <Routes>
            <Route path="/authenticate" element={<Authentication />} />
            <Route
              // path="/dashboard/*"
              path="/dashboard"
              element={
                <ProtectedRoute>
                  {/* <Dashboard /> */}
                  <Dashboard1 />
                </ProtectedRoute>
              }
            />
            {/* <Route path="/mobile" element={<ProtectedRoute><MobileView /></ProtectedRoute>} /> */}
            <Route path="/" element={<Navigate to="/dashboard" />} />
            {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
          </Routes>
        </BrowserRouter>
        <ToastContainer />
    </div>
  );
}

export default App;
