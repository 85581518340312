import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FanControlAction, FanSpeedControlAction, GetCommissionerStatus, GetDevicesList, LightControlAction, SendKeyAction, StartCommissionerAction, StopCommissionerAction } from '../../Redux/actions/devicesAction';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AddIcon from "@mui/icons-material/Add";
import { PiFanFill } from "react-icons/pi";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DevicesPage = () => {
  console.log("--------- DevicesPage.jsx ---------");

  const dispatch = useDispatch();
  const { devices } = useSelector(state => state.devicesReducer, shallowEqual);

  useEffect(() => {
    function fetchData() {
      dispatch(GetDevicesList());
    }

    // Run fetchData initially
    fetchData();

    // // Set up interval to run fetchData every 15 seconds
    // const intervalId = setInterval(fetchData, 15000);

    // // Cleanup function to clear interval on component unmount
    // return () => clearInterval(intervalId);
  }, []);

  if (devices.length == 0) {
      return (
          <Box
              sx={{
                  position: "relative",
                  width: "100%",
                  height: "72vh",
                  // backgroundColor: "lightBlue",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
              }}
          >
              No devices found.
          </Box>
      )
  }

  return (
    <div>
      <DevicesListCard devicesList={devices} />
    </div>
  )
}

const DevicesListCard = ({ devicesList }) => {
  const [showDeviceDetails, setShowDeviceDetails] = useState(null);

  const toggleDeviceDetails = (deviceId) => {
    setShowDeviceDetails(showDeviceDetails === deviceId ? null : deviceId);
  };

  return (
    <Box marginBottom={12}>
      <Card
        sx={{
          position: 'sticky',
          top: 57,
          zIndex: 1000,
          marginBottom: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            component="div"
          >
            Available Devices
          </Typography>
        </CardContent>
        <CardActions>
          {/* <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            // onClick={() => openAddRoomDialog(floorNumber)}
            style={{ marginTop: 2 }}
          >
            Add Device
          </Button> */}
        </CardActions>
      </Card>

      <Grid
        container
        spacing={2}
        marginBottom={2}
        sx={{
          justifyContent: { xs: "center", sm: "flex-start" }
        }}
      >
        {devicesList?.length > 0 &&
          devicesList.map((el) => {
            return (
              <Grid item key={`${el.endpoint}-${el.id}`}>
                {el.id === 32788 && <LightDeviceCard device={el} showDeviceDetails={showDeviceDetails} toggleDeviceDetails={() => toggleDeviceDetails(el.device_id)} icon="" />}
                {el.id === 32790 && <FanDeviceCard device={el} showDeviceDetails={showDeviceDetails} toggleDeviceDetails={() => toggleDeviceDetails(el.device_id)} icon="" />}
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}

const LightDeviceCard = ({ device, showDeviceDetails, toggleDeviceDetails }) => {
  const dispatch = useDispatch();
  const [isOn, setIsOn] = useState(device?.resourcedefs[0]?.value ? device?.resourcedefs[0]?.value : false);
  const isDeviceActive = device?.is_active;
  const showDetails = showDeviceDetails === device.device_id;

  useEffect(() => {

  }, [device?.resourcedefs[0]?.value]);

  const handleSwitchChange = () => {
    setIsOn(prevIsOn => {
      const newValue = !prevIsOn;
      lightControl(newValue);
      return newValue;
    });
  };

  const lightControl = (value) => {
    dispatch(LightControlAction(device, value))
      .then(res => {
        if (res) {
          dispatch(GetDevicesList());

          // toast.dark("Data sent successfully", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   type: "success",
          // });

          console.log('Data sent successfully');
        }
        else {
          setIsOn(!value);

          toast.dark("Failed to send data", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log("Failed to send data");
        }
      });
  }

  return (
    <Card variant="outlined" style={{ width: 270, borderRadius: 9 }}>
      <CardContent>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6" gutterBottom style={{ color: isDeviceActive ? 'inherit' : '#999' }}>
            {/* {device?.endpoint} */}
            {`Lightbulb-${device?.device_id}`}
          </Typography>
          <Box
            boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
            borderRadius="50%"
            p={1}
            bgcolor="transparent"
            display="inline-block"
            // marginTop={1}
            marginBottom={2}
          >
            <LightbulbIcon style={{ fontSize: 45, margin: 7, color: isDeviceActive ? (isOn ? `rgb(255, 165, 0)` : 'inherit') : '#999' }} />
          </Box>

          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h6" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power</Typography>
            <Switch checked={isOn} onChange={handleSwitchChange} disabled={!isDeviceActive} />
          </Box>

          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Is Online:</Typography>
            <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isDeviceActive ? "True" : "False"}</Typography>
          </Box>

          {showDetails && (
            <>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Light Status:</Typography>
                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isOn ? "ON" : "OFF"}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Default Voltage:</Typography>
                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{device?.resourcedefs[2]?.value}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Voltage:</Typography>
                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[4]?.value)?.toFixed(2)} V`}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Current:</Typography>
                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[5]?.value)?.toFixed(3)} A`}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power:</Typography>
                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[6]?.value)?.toFixed(2)} W`}</Typography>
              </Box>
            </>
          )}

          <Button disabled={!isDeviceActive} onClick={toggleDeviceDetails}>
            {showDetails ? 'Show Less' : 'Show More'}
          </Button>
        </Stack>

      </CardContent>
    </Card>
  );
}

const FanDeviceCard = ({ device, showDeviceDetails, toggleDeviceDetails }) => {
  const dispatch = useDispatch();
  const [isOn, setIsOn] = useState(device?.resourcedefs[1]?.value ? device?.resourcedefs[1]?.value : false);
  const [speed, setSpeed] = useState(device?.resourcedefs[2]?.value ? device?.resourcedefs[2]?.value : 0);
  const isDeviceActive = device?.is_active;
  const showDetails = showDeviceDetails === device.device_id;

  const handleSwitchChange = () => {
    setIsOn(prevIsOn => {
      const newValue = !prevIsOn;
      fanControl(newValue);
      return newValue;
    });
  };

  const handleSliderChange = (e) => {
    const updatedValue = e.target.value;
    console.log(updatedValue);
    // fanSpeedControl(updatedValue);
    // setSpeed(updatedValue);
    debounceFn(updatedValue);
  };

  let debounceTimer;
  const debounceFn = (updatedValue) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      fanSpeedControl(updatedValue);
      setSpeed(updatedValue);
    }, 500); // 500 ms
  };

  const fanControl = (value) => {
    dispatch(FanControlAction(device, value))
      .then(res => {
        if (res) {
          dispatch(GetDevicesList());

          // toast.dark("Data sent successfully", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   type: "success",
          // });

          console.log('Data sent successfully');
        }
        else {
          setIsOn(!value);

          toast.dark("Failed to send data", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log("Failed to send data");
        }
      });
  }

  const fanSpeedControl = (value) => {
    dispatch(FanSpeedControlAction(device, value))
      .then(res => {
        if (res) {
          dispatch(GetDevicesList());

          // toast.dark("Data sent successfully", {
          //   position: "bottom-right",
          //   autoClose: 1000,
          //   type: "success",
          // });

          console.log('Data sent successfully');
        }
        else {
          toast.dark("Failed to send data", {
            position: "bottom-right",
            autoClose: 1000,
            type: "error",
          });

          console.log("Failed to send data");
        }
      });
  }

  return (
    <Card variant="outlined" style={{ width: 270, borderRadius: 9 }}>
      <CardContent>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6" gutterBottom style={{ color: isDeviceActive ? 'inherit' : '#999' }}>
            {/* {device?.endpoint} */}
            {`Fan-${device?.device_id}`}
          </Typography>
          <Box
            boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
            borderRadius="50%"
            p={1}
            bgcolor="transparent"
            display="inline-block"
            // marginTop={1}
            marginBottom={2}
          >
            <PiFanFill style={{ fontSize: 45, margin: 7, color: isDeviceActive ? (isOn ? `rgb(255, 165, 0)` : 'inherit') : '#999' }} />
          </Box>

          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h6" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power</Typography>
            <Switch checked={isOn} onChange={handleSwitchChange} disabled={!isDeviceActive} />
          </Box>

          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Is Online:</Typography>
            <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isDeviceActive ? "True" : "False"}</Typography>
          </Box>

          {showDetails && (
            <>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Fan Status:</Typography>
                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isOn ? "ON" : "OFF"}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Speed Control:</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  onChange={handleSliderChange}
                  defaultValue={device?.resourcedefs[2]?.value}
                  step={1}
                  marks
                  min={0}
                  max={5}
                  style={{ width: '100px' }}
                  disabled={!isDeviceActive}
                />
              </Box>
            </>
          )}

          <Button disabled={!isDeviceActive} onClick={toggleDeviceDetails}>
            {showDetails ? 'Show Less' : 'Show More'}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default DevicesPage;
