import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Grid,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import EmailTextField, { validateEmail } from "../TextFields/EmailTextField";
import { useDispatch } from "react-redux";
import PhoneNumberTextField, { validatePhoneNumber } from "../TextFields/PhoneNumberTextField";
import { forgotPasswordUserVerify } from "../../Redux/actions/authAction";
// import { forgotPassword } from "../../Redux/actions/authAction";

const LogoImg = styled("img")({
  width: 50,
});

const ForgotPassword = ({ onLoginClick, onSignupClick, onSendOtpClick }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      return;
    } else {
      setEmailError("");
    }


    dispatch(forgotPasswordUserVerify({email: email, phone: "", onSendOtpClick}))

    setEmail("");
    setEmailError("");
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
      }}
    >
      <Avatar variant="rounded" sx={{ m: 1, mb: 2, bgcolor: "white", width: 270, height: 55 }}>
        <LogoImg sx={{width: "100%"}} src="/logo1.png" alt="Logo"  />
      </Avatar>
      <Typography component="h1" variant="h5">
        Forgot Password
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <EmailTextField
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          setEmailError={setEmailError}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 1, backgroundColor: "#2196F3", color: "#ffffff" }}
        >
          Send OTP
        </Button>
        <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
        <hr style={{ flex: 1, margin: "0 8px" }} />
        <span style={{ margin: "0 8px" }}>or</span>
        <hr style={{ flex: 1, margin: "0 8px" }} />
      </div>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
          onClick={onLoginClick}
        >
          Back to Sign in
        </Button>
        {/* <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
        <hr style={{ flex: 1, margin: "0 8px" }} />
        <span style={{ margin: "0 8px" }}>or</span>
        <hr style={{ flex: 1, margin: "0 8px" }} />
      </div>
        
      <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="space-between">
            <Button
            
        variant="contained"
        sx={{ mt: 1, mb: 1 }}
              onClick={onLoginClick}>
              Back to Sign in
            </Button>
            <Button
            
        variant="contained"
        sx={{ mt: 1, mb: 1 }}
              onClick={onSignupClick}>
              Back to Sign Up
            </Button>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
};

export default ForgotPassword;
