import actionTypes from "../actions/actionTypes";

const initialState = {
    otbrs: [],
    commissioners: [],
    devices: [],
    isGetDevicesLoading: false,
    commissionerState: "disabled"
};

const devicesReducer = (state = initialState, {type, payload}) => {
    console.log(type, payload);

    switch (type) {
        case actionTypes.GET_OTBR_LIST:
            return {
                ...state,
                otbrs: [...payload]
            }
        case actionTypes.GET_COMMISSIONER_LIST:
            return {
                ...state,
                commissioners: [...payload]
            }
        case actionTypes.GET_DEVICES_LIST_REQUEST:
            return {
                ...state,
                isGetDevicesLoading: true,
            }
        case actionTypes.GET_DEVICES_LIST_SUCCESS:
            return {
                ...state,
                isGetDevicesLoading: false,
                devices: [...payload]
            }
        case actionTypes.GET_DEVICES_LIST_FAILED:
            return {
                ...state,
                isGetDevicesLoading: false,
            }
        case actionTypes.FAILED:
            return {
                ...state
            }
        case actionTypes.GET_Commissioner_Status:
            return {
                ...state,
                commissionerState: payload
            }
        default:
            return state;
    }
}

export default devicesReducer;
