import { TextField } from '@mui/material';
import React from 'react';

const OTPTextField = ({ otp, setOtp, otpError, setOtpError }) => {
  const handleOtpChange = (e) => {
    const otpValue = e.target.value;
    setOtp(otpValue);

    // Validate OTP in real-time
    const otpValidationResult = validateOTP(otpValue);
    if (otpValidationResult !== true) {
      setOtpError(otpValidationResult);
    } else {
      setOtpError("");
    }
  };

  const validateOTP = (value) => {
    // Add your OTP validation logic here
    // Example: Check if it's a 6-digit number
    const otpRegex = /^\d{6}$/;

    if (!otpRegex.test(value)) {
      return "OTP must be a 6-digit number";
    }

    return true;
  };

  return (
    <TextField
      size="small"
            variant="filled"
      margin="normal"
      required
      fullWidth
      name="otp"
      label="OTP"
      type="number" // Using type 'text' for OTP
      id="otp"
      autoComplete="one-time-code"
      value={otp}
      onChange={handleOtpChange}
      error={Boolean(otpError)}
      helperText={otpError}
    />
  );
};

export default OTPTextField;
