import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import EmailTextField, { validateEmail } from "../TextFields/EmailTextField";
import PasswordTextField, {
  validatePassword,
} from "../TextFields/PasswordTextField";
import PhoneNumberTextField, {
  validatePhoneNumber,
} from "../TextFields/PhoneNumberTextField";
import FullNameTextField from "../TextFields/FullNameTextField";
import { useDispatch } from "react-redux";
import { signUp } from "../../Redux/actions/authAction";
import GoogleIcon from "@mui/icons-material/Google";
import { google_oauth_backend_url } from "../../Redux/apis";

const LogoImg = styled("img")({
  width: 50,
});

const Signup = ({ onLoginClick, onSignupOTPClick }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [acceptTermsError, setAcceptTermsError] = useState("");
  const dispatch = useDispatch();

  const handleGoogleLogin = () => {
    // Redirect to the Google OAuth login page
    window.location.href = google_oauth_backend_url;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate full name
    if (fullName.trim() === "") {
      setFullNameError("Full Name is required");
      return;
    } else {
      setFullNameError("");
    }

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      return;
    } else {
      setEmailError("");
    }

    // Validate phone number
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneNumberError("Invalid phone number");
      return;
    } else {
      setPhoneNumberError("");
    }

    // Validate password
    const passwordValidationResult = validatePassword(password);
    if (passwordValidationResult !== true) {
      setPasswordError(passwordValidationResult);
      return;
    } else {
      setPasswordError("");
    }

    // Validate acceptance of terms
    if (!acceptTerms) {
      setAcceptTermsError("You must accept the terms and conditions");
      return;
    } else {
      setAcceptTermsError("");
    }

    // Implement your signup logic here
    // console.log("Signup submitted");
    // console.log("Full Name:", fullName);
    // console.log("Email:", email);
    // console.log("Phone Number:", phoneNumber);
    // console.log("Password:", password);
    // console.log("Accepted Terms:", acceptTerms);

    dispatch(
      signUp({
        fullname: fullName,
        email,
        password,
        phone: phoneNumber,
        onSignupOTPClick,
      })
    );

    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setAcceptTerms(false);
    setFullNameError("");
    setEmailError("");
    setPhoneNumberError("");
    setPasswordError("");
    setAcceptTermsError("");
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
      }}
    >
      <Avatar variant="rounded" sx={{ m: 1, mb: 2, bgcolor: "white", width: 270, height: 55 }}>
        <LogoImg sx={{width: "100%"}} src="/logo1.png" alt="Logo"  />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <FullNameTextField
          fullName={fullName}
          setFullName={setFullName}
          fullNameError={fullNameError}
          setFullNameError={setFullNameError}
        />
        <EmailTextField
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          setEmailError={setEmailError}
        />
        <PhoneNumberTextField
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneNumberError={phoneNumberError}
          setPhoneNumberError={setPhoneNumberError}
        />
        <PasswordTextField
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
          }
          label="I accept the terms and conditions"
        />
        <Typography variant="body2" color="error">
          {acceptTerms ? null : acceptTermsError}
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 1, backgroundColor: "#2196F3", color: "#ffffff" }}
        >
          Sign Up
        </Button>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 1 }}
          onClick={onLoginClick}
        >
          Sign In
        </Button>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
        >
          <hr style={{ flex: 1, margin: "0 8px" }} />
          <span style={{ margin: "0 8px" }}>or</span>
          <hr style={{ flex: 1, margin: "0 8px" }} />
        </div>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mt: 1, mb: 2 }}
          onClick={handleGoogleLogin}
          startIcon={<GoogleIcon />}
        >
          Sign in with Google
        </Button>
      </Box>
    </Box>
  );
};

export default Signup;
