import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, RadioGroup, Select, Slider, Stack, Switch, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AddBuildingDetails, AddFloorRoomDetails, GetBuildingDetails, GetFloorRoomDetails } from '../../Redux/actions/buildingAction';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AddDeviceToFloorRoom, FanControlAction, FanSpeedControlAction, GetDevicesList, LightControlAction, RemoveDeviceFromFloorRoom } from '../../Redux/actions/devicesAction';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { PiFanFill } from "react-icons/pi";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { lightBlue } from '@mui/material/colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';

const Home2 = () => {
    console.log("--------- Home2.jsx ---------");

    const [showDevices, setShowDevices] = useState(false);
    const [buildingName, setBuildingName] = useState("");
    const [floorNumber, setFloorNumber] = useState(0);
    const [roomName, setRoomName] = useState("");
    const [floorRoomSelectedImage, setFloorRoomSelectedImage] = useState("bedroom");
    const [selectedFloorId, setSelectedFloorId] = useState();
    const [selectedFloorNumber, setSelectedFloorNumber] = useState();
    const [selectedRoomName, setSelectedRoomName] = useState();
    const [buildingExists, setBuildingExists] = useState(false);
    const [floorRoomExists, setFloorRoomExists] = useState(false);
    const [isAddBuildingDialogOpen, setAddBuildingDialogOpen] = useState(false);
    const [isAddFloorRoomDialogOpen, setAddFloorRoomDialogOpen] = useState(false);
    const [isAddDeviceDialogOpen, setAddDeviceDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const { buildingDetails } = useSelector(state => state.buildingReducer, shallowEqual);
    // console.log(buildingDetails);
    const { floorsRoomsDetails } = useSelector(state => state.buildingReducer, shallowEqual);
    // console.log(floorsRoomsDetails);
    const [floors, setFloors] = useState({});
    // console.log(floors);
    const { devices } = useSelector(state => state.devicesReducer, shallowEqual);
    const [filteredDevicesList, setFilteredDevicesList] = useState([]);
    const [filteredAvailableDevicesList, setFilteredAvailableDevicesList] = useState([]);
    const { isGetDevicesLoading } = useSelector(state => state.devicesReducer, shallowEqual);
    console.log("isGetDevicesLoading", isGetDevicesLoading);
    const { isInitialLoading } = useSelector(state => state.buildingReducer, shallowEqual);
    console.log("isInitialLoading", isInitialLoading);
    const [selectedFloorTab, setSelectedFloorTab] = useState(0);

    const handleFloorTabChange = (event, newValue) => {
        setSelectedFloorTab(newValue);
    }

    useEffect(() => {
        if (selectedFloorId && selectedRoomName) {
            let newDevices = devices.filter(device => device.floor_id == selectedFloorId);
            setFilteredDevicesList(newDevices);
        }
    }, [devices, selectedFloorId]);

    useEffect(() => {
        let availableDevices = devices.filter(device => (device.floor_id == null) && (device.is_active == true));
        setFilteredAvailableDevicesList(availableDevices);
    }, [devices]);

    useEffect(() => {
        if (buildingDetails.id) {
            setBuildingExists(true);
        }
    }, [buildingDetails]);

    useEffect(() => {
        if (buildingDetails.id && floorsRoomsDetails.length > 0) {
            setFloorRoomExists(true);
        }

        if (floorsRoomsDetails.length > 0) {
            let floors1 = {};

            floorsRoomsDetails.forEach(room => {
                if (!floors1[room.floor_number]) {
                    floors1[room.floor_number] = [];
                }
                floors1[room.floor_number].push(room);
            })

            setFloors(floors1);
        }
    }, [floorsRoomsDetails]);

    const openAddBuildingDialog = () => {
        setAddBuildingDialogOpen(true);
    };

    const closeAddBuildingDialog = () => {
        setAddBuildingDialogOpen(false);
    };

    const handleAddBuilding = (newBuildingName) => {
        console.log(newBuildingName);

        if (newBuildingName.length == 0) {
            toast.dark("Building name cannot be empty.", {
                position: "bottom-right",
                autoClose: 1000,
                type: "error",
            });

            console.log('Building name cannot be empty.');

            return;
        }

        if (newBuildingName.length < 3) {
            toast.dark("Building name is too short.", {
                position: "bottom-right",
                autoClose: 1000,
                type: "error",
            });

            console.log('Building name is too short.');

            return;
        }

        dispatch(AddBuildingDetails(newBuildingName))
            .then(res => {
                if (res) {
                    setBuildingExists(true);

                    dispatch(GetBuildingDetails());

                    // toast.dark("Building successfully added", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "success",
                    // });

                    // console.log('Building successfully added');
                }
                else {
                    // toast.dark("Failed to add building", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "error",
                    // });

                    // console.log('Failed to add building');
                }
            })
            .finally(() => {
                setBuildingName("");
            })
    };

    const openAddFloorRoomDialog = () => {
        setAddFloorRoomDialogOpen(true);
    };

    const closeAddFloorRoomDialog = () => {
        setAddFloorRoomDialogOpen(false);
    };

    const handleAddFloorRoom = ({ floorNumber, roomName, imageUrl }) => {
        console.log(floorNumber, roomName, imageUrl);

        if (roomName.length == 0) {
            toast.dark("Room name cannot be empty.", {
                position: "bottom-right",
                autoClose: 1000,
                type: "error",
            });

            console.log('Room name cannot be empty.');

            return;
        }

        if (roomName.length < 3) {
            toast.dark("Room name is too short.", {
                position: "bottom-right",
                autoClose: 1000,
                type: "error",
            });

            console.log('Room name is too short.');

            return;
        }

        if (imageUrl.length == 0) {
            toast.dark("Image url cannot be empty.", {
                position: "bottom-right",
                autoClose: 1000,
                type: "error",
            });

            console.log('Image url cannot be empty.');

            return;
        }

        dispatch(AddFloorRoomDetails({ house_id: buildingDetails.id, floor_number: floorNumber, room_name: roomName, image_url: imageUrl }))
            .then(res => {
                if (res) {
                    setFloorRoomExists(true);

                    dispatch(GetFloorRoomDetails({ house_id: buildingDetails.id }));

                    toast.dark("Floor & Room successfully added", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "success",
                    });

                    console.log('Floor & Room successfully added');
                }
                else {
                    toast.dark("Failed to add floor & room", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log('Failed to add floor & room');
                }
            })
            .finally(() => {
                setFloorNumber(0);
                setRoomName("");
            })
    };

    const handleViewRoomDevices = ({ floorId, floorNumber, roomName }) => {
        console.log("floorId:", floorId, " floorNumber:", floorNumber, " roomName:", roomName);

        dispatch(GetDevicesList());

        setSelectedFloorId(floorId);
        setSelectedFloorNumber(floorNumber);
        setSelectedRoomName(roomName);

        setShowDevices(true);
    }

    const openAddDeviceDialog = () => {
        setAddDeviceDialogOpen(true);

        dispatch(GetDevicesList());
    };

    const closeAddDeviceDialog = () => {
        setAddDeviceDialogOpen(false);
    };

    const handleAddDevice = ({ deviceId }) => {
        console.log("deviceId:", deviceId, " selectedFloorId:", selectedFloorId);

        dispatch(AddDeviceToFloorRoom({ device_id: deviceId, floor_id: selectedFloorId }))
            .then(res => {
                if (res) {
                    dispatch(GetDevicesList());

                    toast.dark("Device successfully added to room", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "success",
                    });

                    console.log('Device successfully added to room');
                }
                else {
                    toast.dark("Failed to add device to room", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log('Failed to add device to room');
                }
            })
    };

    if (floorsRoomsDetails.length == 0 && isInitialLoading) {
        return (
            null
        );
    }

    if (showDevices) {
        return (
            <div>
                <Stack direction="column" spacing={1}>
                    {/* <Box style={{ display: 'flex', alignItems: 'center', color: 'blue' }}>
                        <ArrowBackIcon onClick={() => { setShowDevices(false); setFilteredDevicesList([]); setSelectedFloorId(); setSelectedRoomName() }} style={{ cursor: 'pointer', marginRight: '5px' }} />
                        <Typography onClick={() => { setShowDevices(false); setFilteredDevicesList([]); setSelectedFloorId(); setSelectedRoomName() }} style={{ cursor: 'pointer' }} variant="h6">
                            Back
                        </Typography>
                    </Box> */}

                    <Card
                        sx={{
                            mt: 12,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <CardContent>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <ArrowBackIcon color='primary' onClick={() => { setShowDevices(false); setFilteredDevicesList([]); setSelectedFloorId(); setSelectedRoomName() }} style={{ cursor: 'pointer', marginRight: '5px' }} />
                                <Typography pl={1} variant="h5" component="div">
                                    {`Floor ${selectedFloorNumber} / ${selectedRoomName}`}
                                </Typography>
                            </Box>
                            {/* <Typography variant="h5" component="div">
                                {`Floor ${selectedFloorNumber} / ${selectedRoomName}`}
                            </Typography> */}
                        </CardContent>
                        <CardActions>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => openAddDeviceDialog()}
                                style={{ marginTop: 2 }}
                            >
                                Add Device
                            </Button> */}
                        </CardActions>
                    </Card>
                </Stack>

                {
                    devices.length == 0 ?
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "72vh",
                                // backgroundColor: "lightBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={openAddDeviceDialog}
                            >
                                Add Device
                            </Button>
                        </Box>
                        :
                        <>
                            <DevicesListCard devicesList={filteredDevicesList} />

                            <Tooltip title="Add device" placement="top">
                                <Fab
                                    color="primary"
                                    onClick={openAddDeviceDialog}
                                    sx={{
                                        position: "fixed",
                                        bottom: {
                                            xs: "72px",
                                            sm: "16px",
                                        },
                                        right: "16px",
                                    }}
                                >
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                        </>
                }

                <AddDeviceDialog
                    open={isAddDeviceDialogOpen}
                    onClose={closeAddDeviceDialog}
                    onAddDevice={handleAddDevice}
                    devices={filteredAvailableDevicesList}
                    isGetDevicesLoading={isGetDevicesLoading}
                />
            </div>
        )
    }

    return (
        <div>
            {
                buildingExists
                    ?
                    floorRoomExists
                        ?
                        (
                            <div>
                                {/* <Grid container spacing={3}>
                                    {Object.keys(floors).map(floorNumber => (
                                        <Grid item xs={12} key={floorNumber}>
                                            <Card
                                                style={{
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography variant="h5" component="div">
                                                        Floor {floorNumber}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => openAddRoomDialog(floorNumber)}
                                                        style={{ marginTop: "10px" }}
                                                    >
                                                        Add Room
                                                    </Button>
                                                </CardActions>
                                            </Card>

                                            <Grid container spacing={2}>
                                                {floors[floorNumber].map(room => (
                                                    <Grid item xs={12} sm={6} md={4} key={room.id}>
                                                        <Card>
                                                            <CardContent
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    color: "text.secondary",
                                                                }}
                                                            >
                                                                <Typography variant="h6" component="div">
                                                                    {room.room_name}
                                                                </Typography>
                                                            </CardContent>
                                                            <CardMedia
                                                                component="img"
                                                                height="140"
                                                                image={`rooms/${room.image_url}.jpg`}
                                                                alt={room.room_name}
                                                            />
                                                            <CardActions
                                                                sx={{ display: "flex", justifyContent: "center" }}
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    sx={{ my: 1 }}
                                                                    onClick={() =>
                                                                        handleViewRoomDevices({ floorId: room.id, floorNumber: room.floor_number, roomName: room.room_name })
                                                                    }
                                                                >
                                                                    View Devices
                                                                </Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid> */}

                                <Box sx={{ width: '100%', mb: 12 }}>
                                    <Card sx={{
                                        position: 'sticky',
                                        top: 57,
                                        zIndex: 1000,
                                        p: 1
                                    }}
                                    >
                                        <Tabs
                                            value={selectedFloorTab}
                                            onChange={handleFloorTabChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            {Object.keys(floors).map((floorNumber, index) => (
                                                <Tab
                                                    label={`Floor ${floorNumber}`}
                                                    sx={{ fontSize: "17px", textTransform: 'smallercase' }}
                                                    key={floorNumber}
                                                />
                                            ))}
                                        </Tabs>
                                    </Card>

                                    {Object.keys(floors).map((floorNumber, index) => (
                                        <TabPanel value={selectedFloorTab} index={index} key={floorNumber}>
                                            <Grid container spacing={2}>
                                                {floors[floorNumber].map(room => (
                                                    <Grid item xs={12} sm={6} md={4} key={room.id}>
                                                        <Card>
                                                            <CardContent
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    color: 'text.secondary',
                                                                }}
                                                            >
                                                                <Typography variant="h6" component="div">
                                                                    {room.room_name}
                                                                </Typography>
                                                            </CardContent>
                                                            <CardMedia
                                                                component="img"
                                                                height="140"
                                                                image={`rooms/${room.image_url}.jpg`}
                                                                alt={room.room_name}
                                                            />
                                                            <CardActions
                                                                sx={{ display: 'flex', justifyContent: 'center' }}
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    sx={{ my: 1 }}
                                                                    onClick={() =>
                                                                        handleViewRoomDevices({
                                                                            floorId: room.id,
                                                                            floorNumber: room.floor_number,
                                                                            roomName: room.room_name,
                                                                        })
                                                                    }
                                                                >
                                                                    View Devices
                                                                </Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TabPanel>
                                    ))}
                                </Box>

                                <Tooltip title="Add Room" placement="top">
                                    <Fab
                                        color="primary"
                                        onClick={openAddFloorRoomDialog}
                                        sx={{
                                            position: "fixed",
                                            bottom: {
                                                xs: "72px",
                                                sm: "16px",
                                            },
                                            right: "16px",
                                        }}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </div>
                        )
                        :
                        (
                            // <div
                            //     style={{
                            //         display: "flex",
                            //         flexDirection: "column",
                            //         minHeight: "70vh",
                            //         justifyContent: "center",
                            //         alignItems: "center",
                            //     }}
                            // >
                            //     <Button
                            //         variant="contained"
                            //         color="primary"
                            //         startIcon={<AddIcon />}
                            //         onClick={openAddFloorRoomDialog}
                            //         style={{ marginTop: "20px" }}
                            //     >
                            //         Add Room
                            //     </Button>
                            // </div>

                            <Box
                                margin={"auto"}
                                sx={{
                                    position: "relative",
                                    width: { xs: "100%", sm: "90%", md: "80%" },
                                    height: "80vh",
                                    // backgroundColor: "lightBlue",
                                    display: "flex",
                                    // justifyContent: "center",
                                    alignItems: "center",
                                    // alignContent: "center",
                                }}
                            >
                                <Stack spacing={3} sx={{ alignItems: "center", width: "100%" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="floor-number">Floor no.</InputLabel>
                                        <Select
                                            id="floor-number"
                                            label="Floor number"
                                            value={floorNumber}
                                            onChange={(e) => setFloorNumber(e.target.value)}
                                        >
                                            <MenuItem value={0}>Floor 0</MenuItem>
                                            <MenuItem value={1}>Floor 1</MenuItem>
                                            <MenuItem value={2}>Floor 2</MenuItem>
                                            <MenuItem value={3}>Floor 3</MenuItem>
                                            <MenuItem value={4}>Floor 4</MenuItem>
                                            <MenuItem value={5}>Floor 5</MenuItem>
                                            <MenuItem value={6}>Floor 6</MenuItem>
                                            <MenuItem value={7}>Floor 7</MenuItem>
                                            <MenuItem value={8}>Floor 8</MenuItem>
                                            <MenuItem value={9}>Floor 9</MenuItem>
                                            <MenuItem value={10}>Floor 10</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        id="room-name"
                                        label="Room name"
                                        variant="outlined"
                                        value={roomName}
                                        onChange={(e) => setRoomName(e.target.value)}
                                        fullWidth
                                    />

                                    <Box style={{ width: "100%", overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                        {["bedroom", "dining-room", "guest-room", "home-office", "kitchen", "living-room", "playroom", "study-room"].map((image, index) => (
                                            <img
                                                key={index}
                                                src={`rooms/${image}.jpg`}
                                                alt={`Image-${index}`}
                                                onClick={() => setFloorRoomSelectedImage(image)}
                                                style={{
                                                    cursor: 'pointer',
                                                    objectFit: 'cover',
                                                    width: 150,
                                                    height: 120,
                                                    border: floorRoomSelectedImage === image ? '3px solid blue' : 'none',
                                                    marginRight: 8,
                                                }}
                                            />
                                        ))}
                                    </Box>

                                    <Button
                                        variant="outlined"
                                        endIcon={<ArrowForwardIcon />}
                                        onClick={() => handleAddFloorRoom({ floorNumber, roomName, imageUrl: floorRoomSelectedImage })}
                                    >
                                        Next
                                    </Button>
                                </Stack>
                            </Box>
                        )
                    :
                    (
                        // <div
                        //     style={{
                        //         display: "flex",
                        //         flexDirection: "column",
                        //         minHeight: "70vh",
                        //         justifyContent: "center",
                        //         alignItems: "center",
                        //     }}
                        // >
                        //     <Button
                        //         variant="contained"
                        //         color="primary"
                        //         startIcon={<AddIcon />}
                        //         onClick={openAddBuildingDialog}
                        //         style={{ marginTop: "20px" }}
                        //     >
                        //         Add Home
                        //     </Button>
                        // </div>

                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "72vh",
                                // backgroundColor: "lightBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Stack margin={"auto"} sx={{ alignItems: "center" }}>
                                <Avatar
                                    alt="Home"
                                    // src="/home.PNG"
                                    src="/home1.png"
                                    sx={{ width: { xs: "270px", sm: "320px" }, height: "auto" }}
                                />
                                <TextField
                                    label="Enter Home Name"
                                    variant="standard"
                                    value={buildingName}
                                    onChange={(e) => setBuildingName(e.target.value)}
                                    sx={{
                                        width: { xs: "250px", sm: "300px" },
                                        size: { xs: "small", sm: "medium" }
                                    }}
                                />
                                <Button
                                    sx={{
                                        mt: 4
                                    }}
                                    variant="outlined"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => handleAddBuilding(buildingName)}
                                >
                                    Next
                                </Button>
                            </Stack>
                        </Box>
                    )
            }

            {/* <AddBuildingDialog
                open={isAddBuildingDialogOpen}
                onClose={closeAddBuildingDialog}
                onAddBuilding={handleAddBuilding}
            /> */}

            <AddFloorRoomDialog
                open={isAddFloorRoomDialogOpen}
                onClose={closeAddFloorRoomDialog}
                onAddFloorRoom={handleAddFloorRoom}
            />
        </div>

    )
}



const AddBuildingDialog = ({ open, onClose, onAddBuilding }) => {
    const [buildingName, setBuildingName] = useState("");

    const handleAddBuilding = () => {
        if (buildingName.trim()) {
            onAddBuilding(buildingName);
            onClose();
        }
        setBuildingName("");
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
                {"Add Home"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    id="building-name"
                    label="Building name"
                    variant="outlined"
                    value={buildingName}
                    onChange={(e) => setBuildingName(e.target.value)}
                    style={{ marginTop: 8 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddBuilding} color="primary">
                    {"Add"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AddFloorRoomDialog = ({ open, onClose, onAddFloorRoom }) => {
    const [floorNumber, setFloorNumber] = useState(0);
    const [roomName, setRoomName] = useState("");
    const [floorRoomSelectedImage, setFloorRoomSelectedImage] = useState("bedroom");

    const handleAddFloorRoom = () => {
        if (roomName.trim()) {
            onAddFloorRoom({ floorNumber, roomName, imageUrl: floorRoomSelectedImage });
            onClose();
        }

        setFloorNumber(0);
        setRoomName("");
    };

    const handleFloorRoomSelectedImageClick = (image) => {
        console.log(image);
        setFloorRoomSelectedImage(image);
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth={"sm"} fullWidth>
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
                {"Add Room"}
            </DialogTitle>
            <DialogContent>
                <FormControl style={{ marginTop: 8 }} fullWidth>
                    <InputLabel id="floor-number">Floor no.</InputLabel>
                    <Select
                        id="floor-number"
                        label="Floor number"
                        value={floorNumber}
                        onChange={(e) => setFloorNumber(e.target.value)}
                    >
                        <MenuItem value={0}>Floor 0</MenuItem>
                        <MenuItem value={1}>Floor 1</MenuItem>
                        <MenuItem value={2}>Floor 2</MenuItem>
                        <MenuItem value={3}>Floor 3</MenuItem>
                        <MenuItem value={4}>Floor 4</MenuItem>
                        <MenuItem value={5}>Floor 5</MenuItem>
                        <MenuItem value={6}>Floor 6</MenuItem>
                        <MenuItem value={7}>Floor 7</MenuItem>
                        <MenuItem value={8}>Floor 8</MenuItem>
                        <MenuItem value={9}>Floor 9</MenuItem>
                        <MenuItem value={10}>Floor 10</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    id="room-name"
                    label="Room name"
                    variant="outlined"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    fullWidth
                    style={{ marginTop: 12, marginBottom: 12 }}
                />

                {/* <Grid container spacing={2}>
                    {["rooms/bedroom.jpg", "rooms/dining-room.jpg", "rooms/guest-room.jpg", "rooms/home-office.jpg", "rooms/kitchen.jpg", "rooms/living-room.jpg", "rooms/playroom.jpg", "rooms/study-room.jpg"].map((image, index) => (
                        <Grid item key={index}>
                            <img src={image} alt={`Image ${index}`} onClick={() => "handleImageSelection(image)"} style={{ cursor: 'pointer', width: 100, height: 100 }} />
                        </Grid>
                    ))}
                </Grid> */}

                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    {["bedroom", "dining-room", "guest-room", "home-office", "kitchen", "living-room", "playroom", "study-room"].map((image, index) => (
                        <img
                            key={index}
                            src={`rooms/${image}.jpg`}
                            alt={`Image-${index}`}
                            onClick={() => handleFloorRoomSelectedImageClick(image)}
                            style={{
                                cursor: 'pointer',
                                objectFit: 'cover',
                                width: 125,
                                height: 110,
                                border: floorRoomSelectedImage === image ? '3px solid blue' : 'none',
                                marginRight: 8,
                            }}
                        />
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddFloorRoom} color="primary">
                    {"Add"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AddDeviceDialog = ({ open, onClose, onAddDevice, devices, isGetDevicesLoading }) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState();

    const handleAddDevice = () => {
        if (selectedDeviceId) {
            onAddDevice({ deviceId: selectedDeviceId });
            onClose();
        }

        setSelectedDeviceId();
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth={"xs"} fullWidth >
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
                {"Add Device"}
            </DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', justifyContent: "space-around", overflowX: 'auto', height: "179px", paddingBottom: 8 }}>
                    {isGetDevicesLoading == true ?
                        (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CircularProgress sx={{ marginRight: 1 }} />
                                Searching for devices...
                            </div>
                        )
                        :
                        devices.length === 0 ?
                            (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    No devices found.
                                </div>
                            )
                            :
                            devices.map(el => (
                                <div key={el.device_id}
                                    onClick={() => {
                                        setSelectedDeviceId(el.device_id);
                                    }}
                                    style={{ marginLeft: 12, marginRight: 12, cursor: "pointer", border: `1px solid ${selectedDeviceId == el.device_id ? "black" : "lightgrey"}`, borderRadius: 6, padding: 12 }}>
                                    {el.id === 32788 && (
                                        <div style={{ textAlign: 'center' }}>
                                            <Box
                                                boxShadow={`${selectedDeviceId == el.device_id ? '0px 0px 3px 0px rgba(0,0,0,0.5)' : '0px 0px 3px 0px rgba(0,0,0,0.2)'}`}
                                                borderRadius="50%"
                                                p={1}
                                                bgcolor="transparent"
                                                marginBottom={2}
                                            >
                                                <LightbulbIcon style={{ fontSize: 60, margin: 7, color: `rgb(255, 255, 0)` }} />
                                            </Box>
                                            <div>{`Lightbulb-${el.device_id}`}</div>
                                        </div>
                                    )}
                                    {el.id === 32790 && (
                                        <div style={{ textAlign: 'center' }}>
                                            <Box
                                                boxShadow={`${selectedDeviceId == el.device_id ? '0px 0px 3px 0px rgba(0,0,0,0.5)' : '0px 0px 3px 0px rgba(0,0,0,0.2)'}`}
                                                borderRadius="50%"
                                                p={1}
                                                bgcolor="transparent"
                                                marginBottom={2}
                                            >
                                                <PiFanFill style={{ fontSize: 60, margin: 7, color: `rgb(255, 165, 0)` }} />
                                            </Box>
                                            <div>{`Fan-${el.device_id}`}</div>
                                        </div>
                                    )}
                                </div>
                            ))
                    }
                </div>
            </DialogContent>

            <DialogActions style={{ justifyContent: 'space-around' }}>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddDevice} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};



const DevicesListCard = ({ devicesList }) => {
    const [showDeviceDetails, setShowDeviceDetails] = useState(null);

    const toggleDeviceDetails = (deviceId) => {
        setShowDeviceDetails(showDeviceDetails === deviceId ? null : deviceId);
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                marginTop={2}
                marginBottom={12}
                sx={{
                    justifyContent: { xs: "center", sm: "flex-start" }
                }}
            >
                {devicesList?.length > 0 &&
                    devicesList.map((el) => {
                        return (
                            <Grid item key={`${el.endpoint}-${el.id}`}>
                                {el.id === 32788 && <LightDeviceCard device={el} showDeviceDetails={showDeviceDetails} toggleDeviceDetails={() => toggleDeviceDetails(el.device_id)} icon="" />}
                                {el.id === 32790 && <FanDeviceCard device={el} showDeviceDetails={showDeviceDetails} toggleDeviceDetails={() => toggleDeviceDetails(el.device_id)} icon="" />}
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
}

const LightDeviceCard = ({ device, showDeviceDetails, toggleDeviceDetails }) => {
    const dispatch = useDispatch();
    const [isOn, setIsOn] = useState(device?.resourcedefs[0]?.value ? device?.resourcedefs[0]?.value : false);
    const isDeviceActive = device?.is_active;
    const showDetails = showDeviceDetails === device.device_id;

    const [selectedMenuOption, setSelectedMenuOption] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleVertIconClick = (event) => {
        setSelectedMenuOption("");

        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const options = [
        'Remove device',
    ];
    const ITEM_HEIGHT = 48;
    const handleSelectedOption = (option) => {
        setSelectedMenuOption(option);

        handleClose();
    }

    useEffect(() => {
        if ((selectedMenuOption != "") && (device.id)) {
            dispatch(RemoveDeviceFromFloorRoom({ device_id: device.device_id }))
                .then(res => {
                    if (res) {
                        dispatch(GetDevicesList());

                        toast.dark("Device successfully removed from room", {
                            position: "bottom-right",
                            autoClose: 1000,
                            type: "success",
                        });

                        console.log('Device successfully removed from room');
                    }
                    else {
                        toast.dark("Failed to remove device from room", {
                            position: "bottom-right",
                            autoClose: 1000,
                            type: "error",
                        });

                        console.log('Failed to remove device from room');
                    }
                })
        }

    }, [selectedMenuOption]);

    useEffect(() => {

    }, [device?.resourcedefs[0]?.value]);

    const handleSwitchChange = () => {
        setIsOn(prevIsOn => {
            const newValue = !prevIsOn;
            lightControl(newValue);
            return newValue;
        });
    };

    const lightControl = (value) => {
        dispatch(LightControlAction(device, value))
            .then(res => {
                if (res) {
                    dispatch(GetDevicesList());

                    // toast.dark("Data sent successfully", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "success",
                    // });

                    console.log('Data sent successfully');
                }
                else {
                    setIsOn(!value);

                    toast.dark("Failed to send data", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to send data");
                }
            });
    }

    return (
        <Card variant="outlined" style={{ width: 270, borderRadius: 9, position: 'relative' }}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleVertIconClick}
                style={{ position: "absolute", top: 2, right: 0 }}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '16ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} selected={option === selectedMenuOption} onClick={() => handleSelectedOption(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>

            <CardContent>
                <Stack spacing={2} alignItems="center">
                    <Typography variant="h6" gutterBottom style={{ color: isDeviceActive ? 'inherit' : '#999' }}>
                        {/* {device?.endpoint} */}
                        {`Lightbulb-${device?.device_id}`}
                    </Typography>
                    <Box
                        boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
                        borderRadius="50%"
                        p={1}
                        bgcolor="transparent"
                        display="inline-block"
                        // marginTop={1}
                        marginBottom={2}
                    >
                        <LightbulbIcon style={{ fontSize: 45, margin: 7, color: isDeviceActive ? (isOn ? `rgb(255, 165, 0)` : 'inherit') : '#999' }} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="h6" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power</Typography>
                        <Switch checked={isOn} onChange={handleSwitchChange} disabled={!isDeviceActive} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Is Online:</Typography>
                        <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isDeviceActive ? "True" : "False"}</Typography>
                    </Box>

                    {showDetails && (
                        <>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Light Status:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isOn ? "ON" : "OFF"}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Default Voltage:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{device?.resourcedefs[2]?.value}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Voltage:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[4]?.value)?.toFixed(2)} V`}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Current:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[5]?.value)?.toFixed(3)} A`}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[6]?.value)?.toFixed(2)} W`}</Typography>
                            </Box>
                        </>
                    )}

                    <Button disabled={!isDeviceActive} onClick={toggleDeviceDetails}>
                        {showDetails ? 'Show Less' : 'Show More'}
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
}

const FanDeviceCard = ({ device, showDeviceDetails, toggleDeviceDetails }) => {
    const dispatch = useDispatch();
    const [isOn, setIsOn] = useState(device?.resourcedefs[1]?.value ? device?.resourcedefs[1]?.value : false);
    const [speed, setSpeed] = useState(device?.resourcedefs[2]?.value ? device?.resourcedefs[2]?.value : 0);
    const isDeviceActive = device?.is_active;
    const showDetails = showDeviceDetails === device.device_id;

    const [selectedMenuOption, setSelectedMenuOption] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleVertIconClick = (event) => {
        setSelectedMenuOption("");

        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const options = [
        'Remove device',
    ];
    const ITEM_HEIGHT = 48;
    const handleSelectedOption = (option) => {
        setSelectedMenuOption(option);

        handleClose();
    }

    useEffect(() => {
        if ((selectedMenuOption != "") && (device.id)) {
            dispatch(RemoveDeviceFromFloorRoom({ device_id: device.device_id }))
                .then(res => {
                    if (res) {
                        dispatch(GetDevicesList());

                        toast.dark("Device successfully removed from room", {
                            position: "bottom-right",
                            autoClose: 1000,
                            type: "success",
                        });

                        console.log('Device successfully removed from room');
                    }
                    else {
                        toast.dark("Failed to remove device from room", {
                            position: "bottom-right",
                            autoClose: 1000,
                            type: "error",
                        });

                        console.log('Failed to remove device from room');
                    }
                })
        }

    }, [selectedMenuOption]);

    const handleSwitchChange = () => {
        setIsOn(prevIsOn => {
            const newValue = !prevIsOn;
            fanControl(newValue);
            return newValue;
        });
    };

    const handleSliderChange = (e) => {
        const updatedValue = e.target.value;
        console.log(updatedValue);
        // fanSpeedControl(updatedValue);
        // setSpeed(updatedValue);
        debounceFn(updatedValue);
    };

    let debounceTimer;
    const debounceFn = (updatedValue) => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            fanSpeedControl(updatedValue);
            setSpeed(updatedValue);
        }, 500); // 500 ms
    };

    const fanControl = (value) => {
        dispatch(FanControlAction(device, value))
            .then(res => {
                if (res) {
                    dispatch(GetDevicesList());

                    // toast.dark("Data sent successfully", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "success",
                    // });

                    console.log('Data sent successfully');
                }
                else {
                    setIsOn(!value);

                    toast.dark("Failed to send data", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to send data");
                }
            });
    }

    const fanSpeedControl = (value) => {
        dispatch(FanSpeedControlAction(device, value))
            .then(res => {
                if (res) {
                    dispatch(GetDevicesList());

                    // toast.dark("Data sent successfully", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "success",
                    // });

                    console.log('Data sent successfully');
                }
                else {
                    toast.dark("Failed to send data", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to send data");
                }
            });
    }

    return (
        <Card variant="outlined" style={{ width: 270, borderRadius: 9, position: 'relative' }}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleVertIconClick}
                style={{ position: "absolute", top: 2, right: 0 }}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '16ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} selected={option === selectedMenuOption} onClick={() => handleSelectedOption(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>

            <CardContent>
                <Stack spacing={2} alignItems="center">
                    <Typography variant="h6" gutterBottom style={{ color: isDeviceActive ? 'inherit' : '#999' }}>
                        {/* {device?.endpoint} */}
                        {`Fan-${device?.device_id}`}
                    </Typography>
                    <Box
                        boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
                        borderRadius="50%"
                        p={1}
                        bgcolor="transparent"
                        display="inline-block"
                        // marginTop={1}
                        marginBottom={2}
                    >
                        <PiFanFill style={{ fontSize: 45, margin: 7, color: isDeviceActive ? (isOn ? `rgb(255, 165, 0)` : 'inherit') : '#999' }} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="h6" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power</Typography>
                        <Switch checked={isOn} onChange={handleSwitchChange} disabled={!isDeviceActive} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Is Online:</Typography>
                        <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isDeviceActive ? "True" : "False"}</Typography>
                    </Box>

                    {showDetails && (
                        <>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Fan Status:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isOn ? "ON" : "OFF"}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Speed Control:</Typography>
                                <Slider
                                    valueLabelDisplay="auto"
                                    onChange={handleSliderChange}
                                    defaultValue={device?.resourcedefs[2]?.value}
                                    step={1}
                                    marks
                                    min={0}
                                    max={5}
                                    style={{ width: '100px' }}
                                    disabled={!isDeviceActive}
                                />
                            </Box>

                        </>
                    )}

                    <Button disabled={!isDeviceActive} onClick={toggleDeviceDetails}>
                        {showDetails ? 'Show Less' : 'Show More'}
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}



const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export default Home2;
