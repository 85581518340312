import React, { useEffect, useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import Login from "../../Components/Authentication/Login";
import Signup from "../../Components/Authentication/SignUp";
import ForgotPassword from "../../Components/Authentication/ForgetPassword";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "../../Components/Authentication/ResetPassword";
import ForgotPasswordOTP from "../../Components/Authentication/ForgotPasswordOTP";
import SignUpVerifyOTP from "../../Components/Authentication/SignUpVerifyOTP";
import { googleOAuthLogin } from "../../Redux/actions/authAction";

const Authentication = () => {
  console.log("--------- Authentication.jsx ---------");

  const [authView, setAuthView] = useState("login"); // 'login', 'signup', 'forgotPassword'
  const auth = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const passwordResetToken = location.state?.passwordResetToken;
//   console.log("Password Reset Token", passwordResetToken);
  const viewComponent = location.state?.viewComponent;
//   console.log("View Component", viewComponent);
const dispatch = useDispatch();

  useEffect(() => {
    // Check if authentication state has changed
    if (auth.isAuthenticated) {
      // Redirect to the dashboard page
      navigate("/dashboard", { replace: true });
    }

    if (passwordResetToken && viewComponent) {
        setAuthView(viewComponent);
    }
  }, [auth.isAuthenticated, navigate]);

  useEffect(() => {
    // Check for the access token in the URL on component mount
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const newToken = params.token;
    const message = params.message;

    // Store the new token in session storage and state
    if (newToken && message) {
      // console.log(newToken, message);

      dispatch(googleOAuthLogin(newToken));
    }
  }, []);

  const handleSwitchView = (view) => {
    setAuthView(view);
    navigate(null, {state: null, replace: true});
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {/* Left Side (Image) */}
      <Grid
        item
        xs={false}
        sm={6}
        sx={{
          display: { xs: 'none', sm: 'flex' }, // Hide for xs (extra small) screens, display for sm (small) and larger screens
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#6eacea', // Updated background color
          padding: '20px',
        }}
      >
        <Typography variant="h4" sx={{ color: 'white', textAlign: 'center', fontSize: { xs: 20, sm: 24, md: 32 }, mb: 2 }}>
          {/* Welcome to AU79 Smart Homes */}
          Welcome to Neubinary Smart Homes
        </Typography>
        <Typography variant="body1" sx={{ color: 'white', textAlign: 'center', fontSize: { xs: 14, sm: 16, md: 18 }, mb: 2 }}>
          {/* Experience the future of smart living with AU79's innovative solutions for your home. */}
          Experience the future of smart living with Neubinary's innovative solutions for your home.
        </Typography>
        <img src="image7.png" alt="Neubinary Smart Homes" style={{ width: '80%', maxWidth: 412, height: 'auto' }} />
      </Grid>


      {/* Right Side (Login, Signup, ForgotPassword Components) */}
      <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      {/* Use dynamic rendering based on authView state */}
      {/* <ResetPassword /> */}
      {authView === "login" && (
        <Login
          onSignupClick={() => handleSwitchView("signup")}
          onForgotPasswordClick={() => handleSwitchView("forgotPassword")}
        />
      )}
      {authView === "signup" && (
        <Signup 
        onLoginClick={() => handleSwitchView("login")}
        onSignupOTPClick={() => handleSwitchView("signupotp")}
         />
      )}
      {authView === "forgotPassword" && (
        <ForgotPassword
          onLoginClick={() => handleSwitchView("login")}
          onSignupClick={() => handleSwitchView("signup")}
          onSendOtpClick={() => handleSwitchView("sentotp")}
        />
      )}
      {authView === "resetPassword" && (
        <ResetPassword
        onLoginClick={() => handleSwitchView("login")}
        onSignupClick={() => handleSwitchView("signup")}
         />
      )}
      {authView === "sentotp" && (
        <ForgotPasswordOTP
        onLoginClick={() => handleSwitchView("login")}
        onSignupClick={() => handleSwitchView("signup")}
         />
      )}
      {authView === "signupotp" && (
        <SignUpVerifyOTP
        onLoginClick={() => handleSwitchView("login")}
        onSignupClick={() => handleSwitchView("signup")}
         />
      )}
      </Grid>
    </Grid>
  );
};

export default Authentication;
