import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import PasswordTextField, {
  validatePassword,
} from "../TextFields/PasswordTextField";
// import EmailTextField, { validateEmail } from "../TextFields/EmailTextField";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../Redux/actions/authAction";
import { Navigate } from "react-router-dom";
import EmailPhoneTextField, {
  validateEmail,
  validatePhoneNumber,
} from "../TextFields/EmailPhoneTextField";
import GoogleIcon from "@mui/icons-material/Google";
import { google_oauth_backend_url } from "../../Redux/apis";

const LogoImg = styled("img")({
  width: 50,
});

const Login = ({ onSignupClick, onForgotPasswordClick }) => {
  const [emailPhone, setEmailPhone] = useState("");
  const [emailPhoneError, setEmailPhoneError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  // console.log(auth);

  const handleGoogleLogin = () => {
    // Redirect to the Google OAuth login page
    window.location.href = google_oauth_backend_url;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email/phone
    if (!validateEmail(emailPhone) && !validatePhoneNumber(emailPhone)) {
      setEmailPhoneError("Invalid email address or phone number");
      return;
    } else {
      setEmailPhoneError("");
    }

    // Validate password
    const passwordValidationResult = validatePassword(password);
    if (passwordValidationResult !== true) {
      setPasswordError(passwordValidationResult);
      return;
    } else {
      setPasswordError("");
    }

    // Implement your login logic here
    // console.log("Login submitted");
    // console.log("Email:", email);
    // console.log("Password:", password);
    // console.log("RememberMe:", rememberMe);

    dispatch(logIn(emailPhone, password, rememberMe));

    setEmailPhone("");
    setPassword("");
    setEmailPhoneError("");
    setPasswordError("");
    setRememberMe(false);
  };

  if (auth.isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Box
      sx={{
        my: 4,
        mx: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
      }}
    >
      <Avatar variant="rounded" sx={{ m: 1, mb: 2, bgcolor: "white", width: 270, height: 55 }}>
        <LogoImg sx={{width: "100%"}} src="/logo1.png" alt="Logo"  />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <EmailPhoneTextField
          emailPhone={emailPhone}
          setEmailPhone={setEmailPhone}
          emailPhoneError={emailPhoneError}
          setEmailPhoneError={setEmailPhoneError}
        />
        <PasswordTextField
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
        />
        <Grid container alignItems="center">
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(!rememberMe)}
                />
              }
              label="Remember me"
            />
          </Grid>
          <Grid item>
            <Link
              href="#"
              variant="body2"
              onClick={(e) => {
                e.preventDefault();
                onForgotPasswordClick();
              }}
            >
              Forgot password?
            </Link>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 1, backgroundColor: "#2196F3", color: "#ffffff" }}
        >
          Sign In
        </Button>
        <Button
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 1 }}
        onClick={
          onSignupClick
        }
      >
        Sign Up
      </Button>
        <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
        <hr style={{ flex: 1, margin: "0 8px" }} />
        <span style={{ margin: "0 8px" }}>or</span>
        <hr style={{ flex: 1, margin: "0 8px" }} />
      </div>

        <Button
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mt: 1, mb: 2 }}
          onClick={handleGoogleLogin}
          startIcon={<GoogleIcon />}
        >
          Sign in with Google
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
