// Profile.jsx
import React, { useState } from 'react';
import { Typography, Paper, TextField, Button, Snackbar, Avatar } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const Profile = () => {
  console.log("--------- Profile.jsx ---------");

  const user = {
    id: 1,
    name: '',
    email: '@example.com',
    phoneNumber: '123-456-7890',
    profilePicture: 'https://via.placeholder.com/150',

    user_id: "8b8vjn6-634",
    fullname: "John Doe",
    email: "john.doe@gmail.com",
    is_email_verified: true,
    phone: "9087654321",
    is_phone_verified: false
  };

  const [editedUser, setEditedUser] = useState({ ...user });
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleChangePassword = () => {
    // Implement the logic to change the user's password
    // For demonstration purposes, show a success message
    setSnackbarMessage('Password changed successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleSaveProfile = () => {
    // Implement the logic to save the user's profile changes
    // For demonstration purposes, show a success message
    setSnackbarMessage('Profile changes saved successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setEditedUser({ ...editedUser, profilePicture: user.profilePicture });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    null
  )

  return (
    <div>
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          User Information
        </Typography>
        <Avatar alt={user.name} src={editedUser.profilePicture} sx={{ width: 100, height: 100 }} />
        <Button variant="outlined" component="label" sx={{ marginTop: 2 }}>
          Upload Picture
          <input type="file" hidden />
        </Button>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          variant="outlined"
          value={editedUser.name}
          onChange={(e) => setEditedUser({ ...editedUser, name: e.target.value })}
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          variant="outlined"
          value={editedUser.email}
          onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
        />
        <TextField
          label="Phone Number"
          fullWidth
          margin="normal"
          variant="outlined"
          value={editedUser.phoneNumber}
          onChange={(e) => setEditedUser({ ...editedUser, phoneNumber: e.target.value })}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveProfile}
          style={{ marginTop: '16px' }}
        >
          Save Changes
        </Button>
      </StyledPaper>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Change Password
        </Typography>
        <TextField
          label="Current Password"
          type="password"
          fullWidth
          margin="normal"
          variant="outlined"
          value={passwordFields.currentPassword}
          onChange={(e) =>
            setPasswordFields({ ...passwordFields, currentPassword: e.target.value })
          }
        />
        <TextField
          label="New Password"
          type="password"
          fullWidth
          margin="normal"
          variant="outlined"
          value={passwordFields.newPassword}
          onChange={(e) => setPasswordFields({ ...passwordFields, newPassword: e.target.value })}
        />
        <TextField
          label="Confirm New Password"
          type="password"
          fullWidth
          margin="normal"
          variant="outlined"
          value={passwordFields.confirmNewPassword}
          onChange={(e) =>
            setPasswordFields({ ...passwordFields, confirmNewPassword: e.target.value })
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangePassword}
          style={{ marginTop: '16px' }}
        >
          Change Password
        </Button>
      </StyledPaper>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Additional Details
        </Typography>
        {/* Add more fields for additional user details */}
      </StyledPaper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default Profile;
