import { Box, Button, Card, CardActions, CardContent, CardMedia, Collapse, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, IconButton, List, ListItem, Menu, MenuItem, Select, Slider, Stack, Switch, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DeregisterOtbrFromUser, FanControlAction, FanSpeedControlAction, GetCommissionerDetails, GetDevicesList, GetOtbrList, LightControlAction, RegisterOtbrToUser, SendKeyAction, StartCommissionerAction } from '../../Redux/actions/devicesAction';
import { toast } from 'react-toastify';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { PiFanFill } from "react-icons/pi";
import RouterIcon from '@mui/icons-material/Router';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from "@mui/icons-material/Add";
import Html5QrcodePlugin from '../../Components/Html5QrcodePlugin';
import ResultContainerPlugin from '../../Components/ResultContainerPlugin';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const OtbrsPage = () => {
    console.log("--------- OtbrsPage.jsx ---------");

    const [showDevices, setShowDevices] = useState(false);
    const [selectedOtbrId, setSelectedOtbrId] = useState();
    const [selectedOtbrName, setSelectedOtbrName] = useState();
    const [isAddOtbrDialogOpen, setAddOtbrDialogOpen] = useState(false);
    const [isAddDeviceDialogOpen, setAddDeviceDialogOpen] = useState(false);
    const { devices } = useSelector(state => state.devicesReducer, shallowEqual);
    const { commissioners } = useSelector(state => state.devicesReducer, shallowEqual);
    console.log("commissioners: ", commissioners[0]);
    const [selectedOTBR, setSelectedOTBR] = useState(null);
    const { otbrs } = useSelector(state => state.devicesReducer, shallowEqual);
    console.log("OTBRs: " + otbrs);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetOtbrList());
        dispatch(GetCommissionerDetails());
    }, []);

    const handleOtbrDevices = ({ otbrId, otbrName }) => {
        console.log("otbrId:", otbrId, " otbrName:", otbrName);

        dispatch(GetDevicesList());

        setSelectedOtbrId(otbrId);
        setSelectedOtbrName(otbrName);

        setShowDevices(true);
    };

    const openAddOtbrDialog = () => {
        setAddOtbrDialogOpen(true);
    };

    const closeAddOtbrDialog = () => {
        setAddOtbrDialogOpen(false);
    };

    const openAddDeviceDialog = () => {
        setAddDeviceDialogOpen(true);

        dispatch(GetDevicesList());
    };

    const closeAddDeviceDialog = () => {
        setAddDeviceDialogOpen(false);
    };

    const handleViewDevices = (id) => {
        setSelectedOTBR(selectedOTBR === id ? null : id);
    };

    const handleAddOtbr = ({ network_name }) => {
        return dispatch(RegisterOtbrToUser({ network_name }))
            .then(res => {
                if (res) {
                    dispatch(GetOtbrList());
                    dispatch(GetDevicesList());

                    return true;
                }
                else {

                    return false;
                }
            })
    }

    const handleAddDevice = ({ joiner_key }) => {
        return dispatch(StartCommissionerAction({ ...commissioners[0] }))
            .then(res => {
                if (res) {
                    return dispatch(SendKeyAction({ ...commissioners[0] }, joiner_key))
                        .then(res1 => {
                            if (res1) {
                                let count = 0;
                                const intervalId = setInterval(() => {
                                    if (count >= 5) {
                                        clearInterval(intervalId);
                                    } else {
                                        dispatch(GetDevicesList());
                                        count++;
                                    }
                                }, 15000); // 15 seconds in milliseconds

                                return true;
                            }
                            else {
                                toast.dark("Invalid key", {
                                    position: "bottom-right",
                                    autoClose: 1000,
                                    type: "error",
                                });

                                console.log('Invalid key');

                                return false;
                            }
                        })
                }
                else {
                    toast.dark("Failed to start commissioner", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to start commissioner");

                    return false;
                }
            })
    }

    if (showDevices) {
        return (
            <div>
                <Stack direction="column" spacing={2}>
                    {/* <Box style={{ display: 'flex', alignItems: 'center', color: 'blue' }}>
                        <ArrowBackIcon onClick={() => { setShowDevices(false); }} style={{ cursor: 'pointer', marginRight: '5px' }} />
                        <Typography onClick={() => { setShowDevices(false); }} style={{ cursor: 'pointer' }} variant="h6">
                            Back
                        </Typography>
                    </Box> */}

                    <Card
                        sx={{
                            mt: 12,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <CardContent>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <ArrowBackIcon color='primary' onClick={() => { setShowDevices(false); setSelectedOtbrId(); setSelectedOtbrName(); }} style={{ cursor: 'pointer', marginRight: '5px' }} />
                                <Typography pl={1} variant="h5" component="div">
                                    {`${selectedOtbrName}`}
                                </Typography>
                            </Box>
                            {/* <Typography variant="h5" component="div">
                                {`${selectedOtbrName}`}
                            </Typography> */}
                        </CardContent>
                        <CardActions>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => openAddDeviceDialog()}
                                style={{ marginTop: 2 }}
                            >
                                Add Device
                            </Button> */}
                        </CardActions>
                    </Card>
                </Stack>

                {
                    devices.length == 0 ?
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "72vh",
                                // backgroundColor: "lightBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={openAddDeviceDialog}
                            >
                                Add Device
                            </Button>
                        </Box>
                        :
                        <>
                            <DevicesListCard devicesList={devices} />

                            <Tooltip title="Add device" placement="top">
                                <Fab
                                    color="primary"
                                    onClick={openAddDeviceDialog}
                                    sx={{
                                        position: "fixed",
                                        bottom: {
                                            xs: "72px",
                                            sm: "16px",
                                        },
                                        right: "16px",
                                    }}
                                >
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                        </>
                }

                <AddDeviceDialog
                    open={isAddDeviceDialogOpen}
                    onClose={closeAddDeviceDialog}
                    onAddDevice={handleAddDevice}
                />
            </div>
        )
    }

    if (otbrs.length == 0) {
        return (
            <Box
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "72vh",
                    // backgroundColor: "lightBlue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={openAddOtbrDialog}
                >
                    Add Otbr
                </Button>

                <AddOtbrDialog
                    open={isAddOtbrDialogOpen}
                    onClose={closeAddOtbrDialog}
                    onAddOtbr={handleAddOtbr}
                />
            </Box>
        )
    }

    return (
        <Box marginBottom={12}>
            <Card
                sx={{
                    position: 'sticky',
                    top: 57,
                    zIndex: 1000,
                    marginBottom: 2,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        component="div"
                    >
                        Available Otbrs
                    </Typography>
                </CardContent>
                <CardActions>

                </CardActions>
            </Card>

            <Container>
                <Grid container spacing={2}>
                    {otbrs.map((otbr) => (
                        <Grid item key={otbr?.device_id} xs={12} sm={6} md={4}>
                            <OTBRCard
                                title={otbr?.resourcedefs[1]?.value}
                                isActive={otbr?.is_active}
                                description={otbr?.endpoint}
                                selectedOTBR={selectedOTBR}
                                otbrId={otbr?.device_id}
                                onClick={() => handleViewDevices(otbr?.device_id)}
                                handleOtbrDevices={() => handleOtbrDevices({ otbrId: otbr?.device_id, otbrName: otbr?.resourcedefs[1]?.value })}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Tooltip title="Add Otbr" placement="top">
                <Fab
                    color="primary"
                    onClick={openAddOtbrDialog}
                    sx={{
                        position: "fixed",
                        bottom: {
                            xs: "72px",
                            sm: "16px",
                        },
                        right: "16px",
                    }}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>

            <AddOtbrDialog
                open={isAddOtbrDialogOpen}
                onClose={closeAddOtbrDialog}
                onAddOtbr={handleAddOtbr}
            />
        </Box>
    )
}

const OTBRCard = ({ title, isActive, description, selectedOTBR, otbrId, onClick, handleOtbrDevices }) => {
    const viewDevices = selectedOTBR === otbrId;
    const dispatch = useDispatch();

    const [selectedMenuOption, setSelectedMenuOption] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleVertIconClick = (event) => {
        setSelectedMenuOption("");

        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const options = [
        'Remove otbr',
    ];
    const ITEM_HEIGHT = 48;
    const handleSelectedOption = (option) => {
        setSelectedMenuOption(option);

        handleClose();
    }

    useEffect(() => {
        if ((selectedMenuOption != "") && title) {
            dispatch(DeregisterOtbrFromUser({ network_name: title }))
            .then(res => {
                if (res) {
                    dispatch(GetOtbrList());
                    dispatch(GetDevicesList());

                    toast.dark("Otbr deregistered successfully", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "success",
                    });

                    console.log('Otbr deregistered successfully');
                }
                else {
                    toast.dark("Failed to deregister otbr", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to deregister otbr");
                }
            })
        }
    }, [selectedMenuOption]);

    const handleDeregisterOtbr = () => {
        
    }

    return (
        <Card sx={{ maxWidth: 345, margin: 'auto', mt: 1, borderRadius: 2, position: 'relative' }}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleVertIconClick}
                style={{ position: "absolute", top: 2, right: 0 }}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '16ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} selected={option === selectedMenuOption} onClick={() => handleSelectedOption(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>

            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h6" component="div" style={{ color: isActive ? 'inherit' : '#999' }}>
                            {title}
                        </Typography>
                        <Typography mt={0.4} variant="body2" color="text.secondary" style={{ color: isActive ? 'inherit' : '#999' }}>
                            {description}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton>
                            <RouterIcon style={{ marginRight: 9, marginBottom: -12, fontSize: 37, color: isActive ? 'inherit' : '#999' }} />
                        </IconButton>
                    </Box>
                </Box>

            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{ mt: -1, mb: 1 }}
                    disabled={!isActive}
                    onClick={() => {
                        onClick();
                        handleOtbrDevices();
                    }
                    }
                >
                    View Devices
                </Button>
            </CardActions>
        </Card>
    );
};



const AddOtbrDialog = ({ open, onClose, onAddOtbr }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    let isRequestInProgress = false;
    const [isQrSelected, setIsQrSelected] = useState(true);
    const [networkName, setNetworkName] = useState("");

    useEffect(() => {
        setNetworkName(null);
        setIsQrSelected(true);
    }, []);

    const isValidJSON = (decodedData) => {
        try {
            let { network_name } = JSON.parse(decodedData);

            if (network_name) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (e) {
            return false;
        }
    }

    const onNewScanResult = (decodedText, decodedResult) => {
        // console.log("DecodedText:", decodedText)
        // console.log("DecodedResult:", decodedResult)

        console.log("isRequestInProgress: ", isRequestInProgress)

        if (!isRequestInProgress && isValidJSON(decodedText)) {
            let { network_name } = JSON.parse(decodedText);
            console.log(network_name);

            isRequestInProgress = true;

            handleAddOtbr({ network_name });
        };
    };

    const handleAddOtbr = ({ network_name }) => {
        if (network_name) {
            onAddOtbr({ network_name })
                .then(res => {
                    if (res) {
                        toast.dark("Otbr registered successfully", {
                            position: "bottom-right",
                            autoClose: 1000,
                            type: "success",
                        });

                        console.log('Otbr registered successfully');

                        setNetworkName("");
                        onClose();
                    }
                    else {
                        toast.dark("Failed to register otbr", {
                            position: "bottom-right",
                            autoClose: 1000,
                            type: "error",
                        });

                        console.log("Failed to register otbr");

                        // isRequestInProgress = false;
                        setNetworkName("");
                        onClose();
                    }
                })
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth={isMobile ? undefined : "xs"}
                fullWidth={!isMobile}
                fullScreen={isMobile}
            >
                <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
                    {`Add OTBR`}
                </DialogTitle>
                <DialogContent>
                    {/* {
                        isQrSelected ?
                            <>
                                <Html5QrcodePlugin
                                    fps={10}
                                    qrbox={250}
                                    disableFlip={false}
                                    qrCodeSuccessCallback={onNewScanResult}
                                />
                            </>
                            :
                            <Box style={{ textAlign: "center" }}>
                                Enter network name
                            </Box>
                    } */}

                    <Box style={{ textAlign: "center" }}>
                        {isQrSelected ? (
                            <>
                                <Html5QrcodePlugin
                                    fps={10}
                                    qrbox={250}
                                    disableFlip={false}
                                    qrCodeSuccessCallback={onNewScanResult}
                                />
                                <Divider />
                                <Button
                                    onClick={() => setIsQrSelected(false)}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginTop: '16px' }}
                                >
                                    Enter Network Name
                                </Button>
                            </>
                        ) : (
                            <Stack spacing={2} margin={"auto"} width={"90%"}>
                                <TextField
                                    label="Enter network name"
                                    value={networkName}
                                    onChange={(e) => setNetworkName(e.target.value)}
                                />
                                <Button
                                    onClick={() => handleAddOtbr({ network_name: networkName })}
                                    variant="contained"
                                    color="primary"
                                >
                                    Send
                                </Button>
                                <Divider />
                                <Button
                                    onClick={() => setIsQrSelected(true)}
                                    variant="contained"
                                    color="primary"
                                >
                                    Scan QR Code
                                </Button>
                            </Stack>
                        )}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    );
};

const AddDeviceDialog = ({ open, onClose, onAddDevice }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    let isRequestInProgress = false;
    const [isQrSelected, setIsQrSelected] = useState(true);
    const [deviceKey, setdeviceKey] = useState("");

    useEffect(() => {
        setdeviceKey(null);
        setIsQrSelected(true);
        dispatch(GetCommissionerDetails());
    }, []);

    const isValidJSON = (decodedData) => {
        try {
            let { joiner_key } = JSON.parse(decodedData);

            if (joiner_key) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (e) {
            return false;
        }
    }

    const onNewScanResult = (decodedText, decodedResult) => {
        // console.log("DecodedText:", decodedText)
        // console.log("DecodedResult:", decodedResult)

        if (!isRequestInProgress && isValidJSON(decodedText)) {
            let { joiner_key } = JSON.parse(decodedText);
            console.log(joiner_key);

            isRequestInProgress = true;

            handleAddDevice({ joiner_key });
        }
    };

    const handleAddDevice = ({ joiner_key }) => {
        if (joiner_key) {
            onAddDevice({ joiner_key })
                .then(res => {
                    if (res) {
                        toast.dark("Key sent successfully", {
                            position: "bottom-right",
                            autoClose: 1000,
                            type: "success",
                        });

                        console.log('Key sent successfully');

                        setdeviceKey("");
                        onClose();
                    }
                    else {
                        // isRequestInProgress = false;
                        setdeviceKey("");
                        onClose();
                    }
                })
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            maxWidth={isMobile ? undefined : "xs"}
            fullWidth={!isMobile}
            fullScreen={isMobile}
        >
            <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
                {"Add Device"}
            </DialogTitle>
            <DialogContent>
                {/* <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
                <ResultContainerPlugin results={decodedResults} /> */}

                <Box style={{ textAlign: "center" }}>
                    {isQrSelected ? (
                        <>
                            <Html5QrcodePlugin
                                fps={10}
                                qrbox={250}
                                disableFlip={false}
                                qrCodeSuccessCallback={onNewScanResult}
                            />
                            <Divider />
                            <Button
                                onClick={() => setIsQrSelected(false)}
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginTop: '16px' }}
                            >
                                Enter Device Key
                            </Button>
                        </>
                    ) : (
                        <Stack spacing={2} margin={"auto"} width={"90%"}>
                            <TextField
                                label="Enter device key"
                                value={deviceKey}
                                onChange={(e) => setdeviceKey(e.target.value)}
                            />
                            <Button
                                onClick={() => handleAddDevice({ joiner_key: deviceKey })}
                                variant="contained"
                                color="primary"
                            >
                                Send
                            </Button>
                            <Divider />
                            <Button
                                onClick={() => setIsQrSelected(true)}
                                variant="contained"
                                color="primary"
                            >
                                Scan QR Code
                            </Button>
                        </Stack>
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};



const DevicesListCard = ({ devicesList }) => {
    const [showDeviceDetails, setShowDeviceDetails] = useState(null);

    const toggleDeviceDetails = (deviceId) => {
        setShowDeviceDetails(showDeviceDetails === deviceId ? null : deviceId);
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                marginTop={2}
                marginBottom={12}
                sx={{
                    justifyContent: { xs: "center", sm: "flex-start" }
                }}
            >
                {devicesList?.length > 0 &&
                    devicesList.map((el) => {
                        return (
                            <Grid item key={`${el.endpoint}-${el.id}`}>
                                {el.id === 32788 && <LightDeviceCard device={el} showDeviceDetails={showDeviceDetails} toggleDeviceDetails={() => toggleDeviceDetails(el.device_id)} icon="" />}
                                {el.id === 32790 && <FanDeviceCard device={el} showDeviceDetails={showDeviceDetails} toggleDeviceDetails={() => toggleDeviceDetails(el.device_id)} icon="" />}
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
}

const LightDeviceCard = ({ device, showDeviceDetails, toggleDeviceDetails }) => {
    const dispatch = useDispatch();
    const [isOn, setIsOn] = useState(device?.resourcedefs[0]?.value ? device?.resourcedefs[0]?.value : false);
    const isDeviceActive = device?.is_active;
    const showDetails = showDeviceDetails === device.device_id;

    useEffect(() => {

    }, [device?.resourcedefs[0]?.value]);

    const handleSwitchChange = () => {
        setIsOn(prevIsOn => {
            const newValue = !prevIsOn;
            lightControl(newValue);
            return newValue;
        });
    };

    const lightControl = (value) => {
        dispatch(LightControlAction(device, value))
            .then(res => {
                if (res) {
                    dispatch(GetDevicesList());

                    // toast.dark("Data sent successfully", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "success",
                    // });

                    console.log('Data sent successfully');
                }
                else {
                    setIsOn(!value);

                    toast.dark("Failed to send data", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to send data");
                }
            });
    }

    return (
        <Card variant="outlined" style={{ width: 270, borderRadius: 9 }}>
            <CardContent>
                <Stack spacing={2} alignItems="center">
                    <Typography variant="h6" gutterBottom style={{ color: isDeviceActive ? 'inherit' : '#999' }}>
                        {/* {device?.endpoint} */}
                        {`Lightbulb-${device?.device_id}`}
                    </Typography>
                    <Box
                        boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
                        borderRadius="50%"
                        p={1}
                        bgcolor="transparent"
                        display="inline-block"
                        // marginTop={1}
                        marginBottom={2}
                    >
                        <LightbulbIcon style={{ fontSize: 45, margin: 7, color: isDeviceActive ? (isOn ? `rgb(255, 165, 0)` : 'inherit') : '#999' }} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="h6" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power</Typography>
                        <Switch checked={isOn} onChange={handleSwitchChange} disabled={!isDeviceActive} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Is Online:</Typography>
                        <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isDeviceActive ? "True" : "False"}</Typography>
                    </Box>

                    {showDetails && (
                        <>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Light Status:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isOn ? "ON" : "OFF"}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Default Voltage:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{device?.resourcedefs[2]?.value}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Voltage:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[4]?.value)?.toFixed(2)} V`}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Current:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[5]?.value)?.toFixed(3)} A`}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{`${Number(device?.resourcedefs[6]?.value)?.toFixed(2)} W`}</Typography>
                            </Box>
                        </>
                    )}

                    <Button disabled={!isDeviceActive} onClick={toggleDeviceDetails}>
                        {showDetails ? 'Show Less' : 'Show More'}
                    </Button>
                </Stack>

            </CardContent>
        </Card>
    );
}

const FanDeviceCard = ({ device, showDeviceDetails, toggleDeviceDetails }) => {
    const dispatch = useDispatch();
    const [isOn, setIsOn] = useState(device?.resourcedefs[1]?.value ? device?.resourcedefs[1]?.value : false);
    const [speed, setSpeed] = useState(device?.resourcedefs[2]?.value ? device?.resourcedefs[2]?.value : 0);
    const isDeviceActive = device?.is_active;
    const showDetails = showDeviceDetails === device.device_id;

    const handleSwitchChange = () => {
        setIsOn(prevIsOn => {
            const newValue = !prevIsOn;
            fanControl(newValue);
            return newValue;
        });
    };

    const handleSliderChange = (e) => {
        const updatedValue = e.target.value;
        console.log(updatedValue);
        // fanSpeedControl(updatedValue);
        // setSpeed(updatedValue);
        debounceFn(updatedValue);
    };

    let debounceTimer;
    const debounceFn = (updatedValue) => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            fanSpeedControl(updatedValue);
            setSpeed(updatedValue);
        }, 500); // 500 ms
    };

    const fanControl = (value) => {
        dispatch(FanControlAction(device, value))
            .then(res => {
                if (res) {
                    dispatch(GetDevicesList());

                    // toast.dark("Data sent successfully", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "success",
                    // });

                    console.log('Data sent successfully');
                }
                else {
                    setIsOn(!value);

                    toast.dark("Failed to send data", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to send data");
                }
            });
    }

    const fanSpeedControl = (value) => {
        dispatch(FanSpeedControlAction(device, value))
            .then(res => {
                if (res) {
                    dispatch(GetDevicesList());

                    // toast.dark("Data sent successfully", {
                    //     position: "bottom-right",
                    //     autoClose: 1000,
                    //     type: "success",
                    // });

                    console.log('Data sent successfully');
                }
                else {
                    toast.dark("Failed to send data", {
                        position: "bottom-right",
                        autoClose: 1000,
                        type: "error",
                    });

                    console.log("Failed to send data");
                }
            });
    }

    return (
        <Card variant="outlined" style={{ width: 270, borderRadius: 9 }}>
            <CardContent>
                <Stack spacing={2} alignItems="center">
                    <Typography variant="h6" gutterBottom style={{ color: isDeviceActive ? 'inherit' : '#999' }}>
                        {/* {device?.endpoint} */}
                        {`Fan-${device?.device_id}`}
                    </Typography>
                    <Box
                        boxShadow='0px 0px 3px 0px rgba(0,0,0,0.3)'
                        borderRadius="50%"
                        p={1}
                        bgcolor="transparent"
                        display="inline-block"
                        // marginTop={1}
                        marginBottom={2}
                    >
                        <PiFanFill style={{ fontSize: 45, margin: 7, color: isDeviceActive ? (isOn ? `rgb(255, 165, 0)` : 'inherit') : '#999' }} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="h6" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Power</Typography>
                        <Switch checked={isOn} onChange={handleSwitchChange} disabled={!isDeviceActive} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Is Online:</Typography>
                        <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isDeviceActive ? "True" : "False"}</Typography>
                    </Box>

                    {showDetails && (
                        <>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Fan Status:</Typography>
                                <Typography variant="body1" align="right" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>{isOn ? "ON" : "OFF"}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Typography variant="body1" align="left" style={{ color: isDeviceActive ? 'inherit' : '#999' }}>Speed Control:</Typography>
                                <Slider
                                    valueLabelDisplay="auto"
                                    onChange={handleSliderChange}
                                    defaultValue={device?.resourcedefs[2]?.value}
                                    step={1}
                                    marks
                                    min={0}
                                    max={5}
                                    style={{ width: '100px' }}
                                    disabled={!isDeviceActive}
                                />
                            </Box>
                        </>
                    )}

                    <Button disabled={!isDeviceActive} onClick={toggleDeviceDetails}>
                        {showDetails ? 'Show Less' : 'Show More'}
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default OtbrsPage;
