import { TextField } from '@mui/material';
import React, { useState } from 'react';
import appMeta from '../../Assets/AppMeta/appMeta';

export const validateEmail = (value) => {
    // Regular expression for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
};

export const validatePhoneNumber = (value) => {
    // Regular expression for a valid phone number
    const phoneRegex = /^[0-9]{10}$/; // Update this regex based on your phone number validation requirements
    return phoneRegex.test(value);
};

const EmailPhoneTextField = ({ emailPhone, setEmailPhone, emailPhoneError, setEmailPhoneError }) => {
    const [isEmail, setIsEmail] = useState(true);

    const handleEmailPhoneChange = (e) => {
        const emailPhoneValue = e.target.value;
        setEmailPhone(emailPhoneValue);

        // Validate email/phone in real-time
        if (!validateEmail(emailPhoneValue) && !validatePhoneNumber(emailPhoneValue)) {
            setEmailPhoneError("Invalid email address or phone number");
        } else {
            setEmailPhoneError("");
        }

        // Determine if it's an email or a phone number
        setIsEmail(validateEmail(emailPhoneValue));
    };

    return (
        <TextField
            size="small"
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="emailPhone"
            label={"Email/Phone"}
            name="emailPhone"
            autoComplete={"email tel"}
            autoFocus
            value={emailPhone}
            onChange={handleEmailPhoneChange}
            error={Boolean(emailPhoneError)}
            helperText={emailPhoneError}
        />
    );
};

export default EmailPhoneTextField;
