import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../Redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Brightness4,
  Brightness7,
  Logout,
  PersonAdd,
  Settings,
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import Home from "../Dashboard/Home";
import Profile from "../Dashboard/Profile";
import SettingsPage from "../Dashboard/Settings";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Dashboard() {
  console.log("--------- Dashboard.jsx ---------");

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authReducer);
  const [selectedItem, setSelectedItem] = React.useState("Home");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Retrieve the theme mode from local storage or use "light" as the default
  const storedThemeMode = localStorage.getItem('themeMode') || 'light';
  const [themeMode, setThemeMode] = React.useState(storedThemeMode);

  const toggleTheme = () => {
    const newThemeMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newThemeMode);

    // Store the updated theme mode in local storage
    localStorage.setItem('themeMode', newThemeMode);
  };

  const customTheme = createTheme({
    palette: {
      mode: themeMode,
    },
  });

  React.useEffect(() => {
    // console.log("Mobile view detected");
  }, [isMobile]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleSignOut = () => {
    // console.log("Handle Sign Out");
    dispatch(signOut());
  };

  const handleListItemClick = (itemName) => {
    setSelectedItem(itemName);
  };

  return (
    <ThemeProvider theme={customTheme}>
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="absolute" open={isMobile ? false : open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          {isMobile ? null : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {selectedItem}
          </Typography>

          <AccountMenu
            themeMode={themeMode}
            toggleTheme={toggleTheme}
            setSelectedItem={setSelectedItem}
            handleSignOut={handleSignOut}
          />
        </Toolbar>
      </AppBar>

      {isMobile ? (
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <BottomNavigation
            showLabels
            value={selectedItem}
            onChange={(event, newValue) => {
              handleListItemClick(newValue);
            }}
          >
            <BottomNavigationAction
              label="Home"
              value="Home"
              icon={<HomeIcon />}
            />
            <BottomNavigationAction
              label="Profile"
              value="Profile"
              icon={<PersonIcon />}
            />
            <BottomNavigationAction
              label="Settings"
              value="Settings"
              icon={<SettingsIcon />}
            />
          </BottomNavigation>
        </Paper>
      ) : (
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {["Home", "Profile", "Settings"].map((text) => (
              <ListItemButton
                key={text}
                selected={text === selectedItem}
                onClick={() => handleListItemClick(text)}
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ListItemIcon>
                  {text === "Home" ? (
                    <HomeIcon />
                  ) : text === "Profile" ? (
                    <PersonIcon />
                  ) : (
                    <Settings />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            ))}
          </List>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 12 }}>
          {selectedItem === "Home" && <Home />}
          {selectedItem === "Profile" && <Profile />}
          {selectedItem === "Settings" && <SettingsPage />}
        </Container>
      </Box>
    </Box>
    </ThemeProvider>
  );
}

function AccountMenu({
  themeMode,
  toggleTheme,
  handleSignOut,
  setSelectedItem,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
            <PersonIcon sx={{ width: 32, height: 32 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            setSelectedItem("Profile");
            handleClose();
          }}
        >
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setSelectedItem("Settings");
            handleClose();
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleSignOut();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          SignOut
        </MenuItem>
        <MenuItem onClick={toggleTheme}>
          <ListItemIcon>
            {themeMode === "light" ? (
              <Brightness4 fontSize="small" />
            ) : (
              <Brightness7 fontSize="small" />
            )}
          </ListItemIcon>
          {themeMode === "light" ? "Dark Mode" : "Light Mode"}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
