import actionTypes from "../actions/actionTypes";

const initialState = {
    buildingDetails: {},
    floorsRoomsDetails: [],
    isInitialLoading: false,
};

const buildingReducer = (state = initialState, { type, payload }) => {
    console.log(type, payload);

    switch (type) {
        case actionTypes.ADD_BUILDING_REQUEST:
            return {
                ...state,
                isInitialLoading: true,
            }
        case actionTypes.ADD_BUILDING_SUCCESS:
            return {
                ...state,
                isInitialLoading: false,
                buildingDetails: { ...payload }
            }
        case actionTypes.ADD_BUILDING_FAILED:
            return {
                ...state,
                isInitialLoading: false,
            }
        case actionTypes.ADD_FLOOR_ROOM_REQUEST:
            return {
                ...state,
                isInitialLoading: true,
            }
        case actionTypes.ADD_FLOOR_ROOM_SUCCESS:
            return {
                ...state,
                isInitialLoading: false,
                floorsRoomsDetails: [...payload]
            }
        case actionTypes.ADD_FLOOR_ROOM_FAILED:
            return {
                ...state,
                isInitialLoading: false,
            }
        default:
            return state;
    }
}

export default buildingReducer;
