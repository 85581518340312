import { combineReducers } from "redux";
import authReducer from "../Redux/reducers/authReducer";
import homeReducer from "./reducers/homeReducer";
import devicesReducer from "./reducers/devicesReducer";
import buildingReducer from "./reducers/buildingReducer";

const RootReducer = combineReducers({
    authReducer: authReducer,
    homeReducer: homeReducer,
    devicesReducer: devicesReducer,
    buildingReducer: buildingReducer
});

export default RootReducer;
