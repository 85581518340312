import { TextField } from '@mui/material';
import React from 'react';

export const validatePhoneNumber = (value) => {
    // Regular expression for a valid phone number
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  };

const PhoneNumberTextField = ({phoneNumber, setPhoneNumber, phoneNumberError, setPhoneNumberError}) => {
    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setPhoneNumber(phoneNumberValue);

        // Validate phone number
        if (!validatePhoneNumber(phoneNumberValue)) {
            setPhoneNumberError("Invalid phone number");
            return;
          } else {
            setPhoneNumberError("");
          }
    }

  return (
    <TextField
          size="small"
            variant="filled"
          margin="normal"
          required
          fullWidth
          id="phoneNumber"
          label="Phone Number"
          name="phoneNumber"
          autoComplete="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          error={Boolean(phoneNumberError)}
          helperText={phoneNumberError}
        />
  )
}

export default PhoneNumberTextField;
