import axios from "axios";
import actionTypes from "./actionTypes";
import useAxios from "../../Hooks/useAxios";
import { url } from '../apis';

const backendUrl = `${url}/SCM`;

export const AddDeviceToFloorRoom = ({ device_id, floor_id }) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .put(`${backendUrl}/devices/add/room`, { device_id, floor_id })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const RemoveDeviceFromFloorRoom = ({ device_id }) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .put(`${backendUrl}/devices/remove/room`, { device_id })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const GetOtbrList = () => {
    let api = useAxios();

    return async (dispatch) => {
        await api
            .get(`${backendUrl}/devices/otbr/list`)
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);
                    dispatch({ type: actionTypes.GET_OTBR_LIST, payload: res?.data?.data });
                }
            })
            .catch(err => {
                console.log(err);

                if (err.response.data.message == "Details not found!") {
                    dispatch({ type: actionTypes.GET_OTBR_LIST, payload: [] });
                }
            })
    }
}

export const GetCommissionerDetails = () => {
    let api = useAxios();

    return async (dispatch) => {
        await api
            .get(`${backendUrl}/devices/commissioner`)
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);
                    dispatch({ type: actionTypes.GET_COMMISSIONER_LIST, payload: res?.data?.data });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export const GetDevicesList = () => {
    let api = useAxios();

    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_DEVICES_LIST_REQUEST });

        await api
            .get(`${backendUrl}/devices/list`)
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    dispatch({ type: actionTypes.GET_DEVICES_LIST_SUCCESS, payload: res?.data?.data });
                }
                else {
                    dispatch({ type: actionTypes.GET_DEVICES_LIST_FAILED });
                }
            })
            .catch(err => {
                console.log(err);

                dispatch({ type: actionTypes.GET_DEVICES_LIST_FAILED });

                if (err.response.data.message == "Details not found!") {
                    dispatch({ type: actionTypes.GET_DEVICES_LIST_SUCCESS, payload: [] });
                }
            })
    }
}

export const GetCommissionerStatus = (device) => {
    let api = useAxios();

    return async (dispatch) => {
        await api
            .get(`${backendUrl}/devices/${device?.endpoint}/${device?.id}/0/1`)
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);
                    dispatch({ type: actionTypes.GET_Commissioner_Status, payload: res?.data?.data?.content?.value });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export const StartCommissionerAction = (device) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .post(`${backendUrl}/devices/${device?.endpoint}/${device?.id}/0/2`)
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const StopCommissionerAction = (device) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .post(`${backendUrl}/devices/${device?.endpoint}/${device?.id}/0/3`)
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const SendKeyAction = (device, value) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .put(`${backendUrl}/devices/${device?.endpoint}/${device?.id}/0/4`, {
                id: 4,
                kind: "singleResource",
                value,
                type: "string"
            })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const LightControlAction = (device, value) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .put(`${backendUrl}/devices/${device?.endpoint}/${device?.id}/0/1`, {
                id: 1,
                kind: "singleResource",
                value,
                type: "boolean"
            })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const FanControlAction = (device, value) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .put(`${backendUrl}/devices/${device?.endpoint}/${device?.id}/0/1`, {
                id: 1,
                kind: "singleResource",
                value,
                type: "boolean"
            })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const FanSpeedControlAction = (device, value) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .put(`${backendUrl}/devices/${device?.endpoint}/${device?.id}/0/2`, {
                id: 2,
                kind: "singleResource",
                value,
                type: "integer"
            })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const RegisterOtbrToUser = ({ network_name }) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .post(`${backendUrl}/devices/register/otbr`, { network_name })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}

export const DeregisterOtbrFromUser = ({ network_name }) => {
    let api = useAxios();

    return async (dispatch) => {
        return await api
            .post(`${backendUrl}/devices/deregister/otbr`, { network_name })
            .then(res => {
                console.log(res);

                if (res.status === 200) {
                    console.log(res?.data);

                    return true;
                }
                else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);

                return false;
            })
    }
}