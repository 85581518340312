import { ADD_DEVICE_SUCCESS, ADD_FLOOR_SUCCESS, ADD_ROOM_SUCCESS, FETCH_DEVICES_SUCCESS, UPDATE_DEVICE_STATUS_SUCCESS } from "../actions/actionTypes";

const initialState = {
  floors: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICES_SUCCESS:
      return {
        ...state,
        floors: action.payload,
      };

    default:
      return state;
  }
};

export default homeReducer;
