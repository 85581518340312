
export const LOGIN_SUCESS_MESSAGE =
   "Welcome to Au79";
export const LOGIN_ERROR_MESSAGE =
  "Invalid Credentials, Kindly Check your username and password";
export const SIGNUP_SUCESS_MESSAGE =
  "Thank You for Completing your Signup";
export const SIGNUP_ERROR_MESSAGE = "";
export const EMAIL_VERIFICATION_SUCCESS_MESSAGE = "";
export const EMAIL_VERIFICATION_ERROR_MESSAGE =
  "Please Enter a Valid OTP";
export const RESEND_OTP_SUCCESS_MESSAGE = "OTP Sent Successfully";
export const RESEND_OTP_ERROR_MESSAGE =
  "Unable to Send OTP, Please try after sometime";
export const PHONE_VERIFICATION_SUCCESS_MESSAGE = "";
export const PHONE_VERIFICATION_ERROR_MESSAGE =
  "Please Enter a Valid OTP";
export const FORGOT_PASSWORD_USER_VERIFY_OTP_SUCCESS_MESSAGE =
  "Verification Code Sent Successfully";
export const FORGOT_PASSWORD_USER_VERIFY_OTP_ERROR_MESSAGE =
  "Please Enter a valid OTP!!!";
export const FORGOT_PASSWORD_NEW_PASSWORD_SUCCESS_MESSAGE = "";
export const FORGOT_PASSWORD_NEW_PASSWORD_ERROR_MESSAGE =
  "Internal error,Please try after sometime"
export const SIGN_OUT_SUCCESS_MESSAGE = "";
export const SIGN_OUT_ERROR_MESSAGE = "";

export const GET_USER_PROFILE_SUCCESS_MESSAGE =
  "Profile Loaded Successfully";
export const GET_USER_PROFILE_ERROR_MESSAGE =
"Error Loading Profile ";

export const GET_RECENT_ACTIVITIES_SUCCESS_MESSAGE =
  "Recent Activities Loaded Successfully";
export const GET_RECENT_ACTIVITIES_ERROR_MESSAGE =
  "Sorry, Unable to fetch Recent Activities";

export const TOKEN_INVALID =
  "Sorry, Please Log-In again to get the User Profile Details!!!";
