
export const url = process.env.REACT_APP_AU79_URL;

export const google_oauth_backend_url = `${url}/SCM/oauth2/google`;

export const setHeaders = () => { 
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  }; 
  return headers;
};
