import { TextField, InputAdornment, IconButton } from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export const validatePassword = (value) => {
  // Minimum password length requirement
  const minLength = 8;

  // Regular expression for a strong password
  // const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

  // Check if the password meets the criteria
  if (value.length < minLength) {
    return "Password must be at least 8 characters long";
  }
  // else if (!strongPasswordRegex.test(value)) {
  //   return "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character";
  // }

  return true;
};


const PasswordTextField = ({password, setPassword, passwordError, setPasswordError}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);

    // Validate password in real-time
    const passwordValidationResult = validatePassword(passwordValue);
    if (passwordValidationResult !== true) {
      setPasswordError(passwordValidationResult);
    } else {
      setPasswordError("");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
          size="small"
            variant="filled"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="password"
          value={password}
          onChange={handlePasswordChange}
          error={Boolean(passwordError)}
          helperText={passwordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
  )
}

export default PasswordTextField;
