import React from 'react';
import {
  Typography,
  Grid,
  Paper,
  Switch,
  FormControlLabel,
  FormGroup,
  TextField,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const SettingsPage = () => {
  console.log("--------- SettingsPage.jsx ---------");

  const handleChangeNotification = () => {
    // Implement the logic to change notification settings
    console.log('Notification settings changed');
  };

  const handleChangeUserPreferences = () => {
    // Implement the logic to change user preferences
    console.log('User preferences changed');
  };

  const handleChangeAppPreferences = () => {
    // Implement the logic to change application preferences
    console.log('Application preferences changed');
  };

  return (
    null
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Device Notifications
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Enable Device Alerts"
              onChange={handleChangeNotification}
            />
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Receive Notifications via Email"
              onChange={handleChangeNotification}
            />
            {/* Add more device notification settings as needed */}
          </FormGroup>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            User Preferences
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Enable Dark Mode"
              onChange={handleChangeUserPreferences}
            />
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Show Device Status on Dashboard"
              onChange={handleChangeUserPreferences}
            />
            {/* Add more user preferences as needed */}
          </FormGroup>
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Application Preferences
          </Typography>
          <TextField
            label="Default Timezone"
            fullWidth
            margin="normal"
            variant="outlined"
            // Add logic to get and set the default timezone
          />
          <TextField
            label="Language"
            fullWidth
            margin="normal"
            variant="outlined"
            // Add logic to get and set the language preference
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeAppPreferences}
            style={{ marginTop: '16px' }}
          >
            Save Preferences
          </Button>
          {/* Add more application preferences as needed */}
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
