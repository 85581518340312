import { TextField } from '@mui/material';
import React from 'react';

const FullNameTextField = ({fullName, setFullName, fullNameError, setFullNameError}) => {
    const handleFullNameChange = (e) => {
        // Validate full name
        const fullNameValue = e.target.value;
        setFullName(fullNameValue);

        if (fullNameValue?.trim() === "") {
            setFullNameError("Full Name is required");
          } else {
            setFullNameError("");
          }
    }

  return (
    <TextField
          size="small"
            variant="filled"
          margin="normal"
          required
          fullWidth
          id="fullName"
          label="Full Name"
          name="fullName"
          autoComplete="name"
          autoFocus
          value={fullName}
          onChange={handleFullNameChange}
          error={Boolean(fullNameError)}
          helperText={fullNameError}
        />
  )
}

export default FullNameTextField;
