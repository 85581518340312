// homeActions.js
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ADD_DEVICE_SUCCESS, ADD_FLOOR_SUCCESS, ADD_ROOM_SUCCESS, FETCH_DEVICES_SUCCESS, UPDATE_DEVICE_STATUS_SUCCESS } from './actionTypes';
import { url } from '../apis';

const backendUrl = `${url}/SCM`;

export const fetchUserDevices = () => {
  return (dispatch) => {
    const authorizationToken =
      localStorage.getItem('token') || sessionStorage.getItem('token') || '';

    axios
      .get(`${backendUrl}/user/devices`, {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      })
      .then((res) => {
        if (res.data?.floors) {
        // console.log(res.data?.floors);

          dispatch({ type: FETCH_DEVICES_SUCCESS, payload: res.data.floors });
        }
      })
      .catch((err) => {
        console.error('Error fetching user devices:', err);
      });
  };
};

export const addFloor = (newFloorName) => {
  return (dispatch) => {
    const authorizationToken =
      localStorage.getItem('token') || sessionStorage.getItem('token') || '';

    axios
      .post(
        `${backendUrl}/floors`,
        { name: newFloorName },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authorizationToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // dispatch({ type: ADD_FLOOR_SUCCESS, payload: res.data });

        dispatch(fetchUserDevices()); // Fetch devices after adding a floor
      })
      .catch((error) => {
        console.error('Error adding floor:', error);
      });
  };
};

export const addRoom = (floorId, roomName) => {
  return (dispatch) => {
    const authorizationToken =
      localStorage.getItem('token') || sessionStorage.getItem('token') || '';

    axios
      .post(
        `${backendUrl}/rooms`,
        { floor_id: floorId, name: roomName },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authorizationToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // dispatch({type: ADD_ROOM_SUCCESS, payload: { floorId, room: res.data }});

        dispatch(fetchUserDevices()); // Fetch devices after adding a room
      })
      .catch((error) => {
        console.error('Error adding room:', error);
      });
  };
};

export const addDevice = (floorId, roomId, device) => {
  return (dispatch) => {
    const authorizationToken =
      localStorage.getItem('token') || sessionStorage.getItem('token') || '';

    axios
      .post(
        `${backendUrl}/devices`,
        { room_id: roomId, name: device?.name },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authorizationToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // dispatch({type: ADD_DEVICE_SUCCESS, payload: { floorId, roomId, device: res.data }});
        
        dispatch(fetchUserDevices()); // Fetch devices after adding a device
      })
      .catch((error) => {
        console.error('Error adding device:', error);
      });
  };
};

export const updateDeviceStatus = (device) => {
  return (dispatch) => {
    const authorizationToken =
      localStorage.getItem('token') || sessionStorage.getItem('token') || '';

    axios
      .patch(
        `${backendUrl}/devices`,
        { device_id: device.id, status: !device.status },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authorizationToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // dispatch({ type: UPDATE_DEVICE_STATUS_SUCCESS, payload: res.data });
        
        dispatch(fetchUserDevices()); // Fetch devices after updating device status
      })
      .catch((error) => {
        console.error('Error updating device status:', error);
      });
  };
};
