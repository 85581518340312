import actionTypes from "../actions/actionTypes";
import actionType from "../actions/actionTypes";

const initialState = {
  error: true,
  isLoading: true,
  token: localStorage.getItem("token"),
  signup_jwt: sessionStorage.getItem("signup_jwt"),
  serviceAreaData: [],
  isAvailable: false,
  // isLoggedIn: false,
  isLoggedIn: (localStorage.getItem("token") || sessionStorage.getItem("token")) ? true : false,
  emailPhoneFPError: false,
  passwordUpdateError: true,
  signUperror: false,
  signUpVerifyerror: false,
  referalError: false,
};


const authReducer = (state = initialState, action) => {
  // console.log(action.type);
  // console.log(action.payload);
  switch (action.type) {
    case actionTypes.SIGN_IN_OAuth:
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload
      }
    case actionType.SIGN_IN:
      return {
        ...state,
        error: action.payload.error,
        token: action.payload.tkn,
        isLoggedIn: !action.payload.error,
        isLoading: false,
      };
    case actionType.PINCODE_AVAILABILITY:
      return {
        ...state,
        error: action.payload.error,
        token: action.payload.tkn,
        serviceAreaData: action.payload.data,
      };
    case actionType.SEND_EMAIL_OTP:
    case actionType.SIGN_UP:
      return {
        ...state,
        signUperror: action.payload.error,
        signup_jwt: action.payload.signup_jwt,
        isAvailable: action.payload.is_available,
      };
    case actionType.EMAIL_VERIFICATION:
      return {
        ...state,
        error: action.payload.error,
        token: action.payload.tkn,
        isLoading: false,
      };
    case actionType.PHONE_VERIFICATION:
      return {
        ...state,
        signUpVerifyerror: action.payload.error,
        token: action.payload.tkn,
        isLoading: false,
      };
    case actionType.RESEND_OTP:
      return {
        ...state,
        error: action.payload.error,
        signup_jwt: action.payload.signup_jwt,
        isLoading: false,
      };
    case actionType.FORGOT_PASSWORD_EMAIL_PHONE:
      return {
        ...state,
        emailPhoneFPError: action.payload.error,
      };

    case actionType.FORGOT_PASSWORD_NEW_PASSWORD:
      return {
        ...state,
        passwordUpdateError: action.payload.error,
        update: action.payload.msg,
        token: action.payload.tkn,
        isLoading: false,
      };

    case actionType.REFERAL_CODE_VERIFY:
      return {
        ...state,
        referalError: action.payload.error,
      };
      
    case actionType.SIGN_OUT:
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      // toast.dark("Goodbye... 🙋‍♂️👋🙋‍♂️", {
      //   type: "success",
      //   position: "bottom-right",
      // });
      return {
        ...initialState,
        isLoggedIn: false
      };
    default:
      return state;
  }
};

export default authReducer;
