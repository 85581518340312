import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Grid,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import PasswordTextField, { validatePassword } from "../TextFields/PasswordTextField";
import { useDispatch } from "react-redux";
// import { resetPassword } from "../../Redux/actions/authAction"; // You need to define the resetPassword action
import { useLocation } from "react-router-dom";

const LogoImg = styled("img")({
  width: 50,
});

const ResetPassword = ({ onLoginClick, onSignupClick }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const passwordResetToken = location.state?.passwordResetToken;
  // console.log("Password Reset Token", passwordResetToken);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate password
    const passwordValidationResult = validatePassword(password);
    if (passwordValidationResult !== true) {
      setPasswordError(passwordValidationResult);
      return;
    } else {
      setPasswordError("");
    }

    // Implement your reset password logic here
    // console.log("Reset password submitted");
    // console.log("Password:", password);

    // Dispatch the resetPassword action with the new password
    // dispatch(resetPassword(passwordResetToken, password, onLoginClick));

    // Clear form fields and errors
    setPassword("");
    setPasswordError("");
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar variant="rounded" sx={{ m: 1, mb: 2, bgcolor: "white", width: 270, height: 55 }}>
        <LogoImg sx={{width: "100%"}} src="/logo1.png" alt="Logo"  />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <PasswordTextField
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Reset Password
        </Button>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="space-between">
            <Link href="#" variant="body2" onClick={(e) => { e.preventDefault(); onLoginClick(); }}>
              Back to Sign in
            </Link>
            <Link href="#" variant="body2" onClick={(e) => { e.preventDefault(); onSignupClick(); }}>
              Back to Sign Up
            </Link>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
};

export default ResetPassword;
